import { Component, OnInit, SimpleChanges, Input, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';

import { PreferencesService } from 'src/app/_services/preferences.service';
import { RecentSearchesService } from 'src/app/_services/recentSearches.service';
import { deepClone } from 'src/app/utils';

@Component({
	selector: 'recent-searches',
	templateUrl: './recent-searches.component.html',
	styleUrls: ['./recent-searches.component.css',
	]
})


export class RecentSearchesComponent implements OnInit {
	
	@Input() parentObject?: any

	public display_save_obj: any = {};
	public newSearchName: string = '';


	constructor(public ms: MechanicsService,
		public qs: QueryParamsService,
		public rss: RecentSearchesService,
		public ps: PreferencesService) {

	
	}

	ngOnInit() {	
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
	}

	get windowWidth(): number {
		return window.innerWidth
	}

	display_save(uid) {
		this.newSearchName = ''
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
		this.display_save_obj[uid] = 'block'
	}


	execute_save(uid) {
		this.rss.save(uid, this.newSearchName)
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
	}


	async execRecentSearch(searchObj: any) {

		const l: string = `pageQuickSearch execRecentSearch() - `

		this._loadRecentSearch(searchObj)

		let subpath = searchObj.endpoint === 'explore' ? 'graphs' : 'results';

		// console.log(`${l}searchObj = `, searchObj)

		let routeElements = { path: this.ms.endpoint, subpath, caller: l };

		if (searchObj.office) {
			/*
				Resolving a very specific bug :
				- Select an office in data coverage, say Algeria (DZ)
				- Make a quick search
				- Select another office, for instance WHO
				- In recent searches, execute the DZ query again

				The problem is, there's /IPO-WHO/ and fcoffice=DZ in the URL, so there's an office conflict.

				In this case, we need to change /IPO-WHO/ to /IPO-DZ/
			*/

			routeElements["office"] = searchObj.office
		}

		const route = this.ms.makeRoute(routeElements)

		// console.log(`${l}made route = '${route}'`)

		await this.qs.queryParamsObjectToUrl(route)
	}

	async openRecentSearch(searchObj: any) {

		const l: string = `pageQuickSearch openRecentSearch() - `

		console.log(`${l}Loading recent serach = `, searchObj)
		let currentView = searchObj.endpoint === this.ms.endpoint

		this._loadRecentSearch(searchObj)

		if (currentView) {

			// queryParamsObjectToUrl() doesn't work here (same page), because the queryparams change, but not the base URL (/quicksearch), so the router does nothing. Replacing this.bricks manually instead.

			let asStructure, brick

			try {
				asStructure = JSON.parse(searchObj.asStructure)
				brick = asStructure.bricks[0];
			} catch (err) {
				console.log(`${l}Caught error : could not parse asStructure! err = `, err)
				return
			}
			try{
				this.parentObject.buildBricksFromQP()
			}
			catch(err){
				this.parentObject.buildStructure()
			}
			document.querySelector(".mainGrid").scroll({
				top: 0,
				behavior: 'smooth'
			});

		} else {
			const route = this.ms.makeRoute({ path: this.ms.endpoint, caller: l })
			await this.qs.queryParamsObjectToUrl(route)
		}
	}

	_loadRecentSearch(searchObj: any): void {

		const l = "pageQuickSearch  _loadRecentSearch() - "

		const searchObjClone = deepClone(searchObj)

		// corresponds to recent search

		// console.log(`${l}searchObj.endpoint = `,searchObj.endpoint)
		this.ms.setEndpoint(searchObj.endpoint)
		delete searchObjClone.endpoint;

		this.qs.queryParams[searchObj.endpoint] = searchObjClone;

		if (searchObj.bases64) {
			this.ms.bases64 = searchObj.bases64
		}
	}
}
