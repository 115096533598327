import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { WOption } from '@wipo/w-angular/shared';
import { DBInfoSolrResponse, SuggestSolrResponse, DBNews } from '../../interfaces';
import { MechanicsService } from '../../_services/mechanics.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { PreferencesService } from '../../_services/preferences.service';
import { SearchService } from '../../_services/search.service';
import { RecentSearchesService } from '../../_services/recentSearches.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone } from '../../utils';
import { OptionsListService } from '../../_services/options-list.service';


@Component({
	selector: 'page-quicksearch',
	templateUrl: './page-quicksearch.component.html',
	styleUrls: ['./page-quicksearch.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class PageQuickSearchComponent implements OnInit {

	public bricks = {
		brandNameStrategy: "Simple",
		applicantStrategy: "Simple",
		numberStrategy: "Either",

		v: "", // Generic search field, will be transformed into brandName, applicant or number, depending what we search for
		searchBy: "" // initialized in ngOnInit()
	}


	public optionsSearchBy: WOption[] = []

	// need translation
	// public helpSearch: any = {
	//	 "brandName": `${this.ms.translate("general_words.example")}: wipo, wip<b>*</b>, w<b>?</b>po, world <b>AND</b> <b>(</b>intell* <b>OR</b> property<b>)</b>`,

	//	 "applicant": `${this.ms.translate("general_words.example")}: wipo, wip<b>*</b>, w<b>?</b>po, world <b>AND</b> <b>(</b>intell* <b>OR</b> property<b>)</b>`,

	//	 "number": `${this.ms.translate("general_words.example")}: 123456, 1234<b>*</b>, XX/Y/12345`
	// }
	public helpSearch: any = {
		"brandName": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
		"applicant": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
		"number": `Ex: 123456, 1234<b>*</b>, XX/Y/12345, US501995075004828 ...`,
		"combined": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
	}

	public recentSearches: any[] = [];

	public recordsCount: string = null
	public news: DBNews[] = [];
	public dataSourcesCount: string = null



	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public ss: SearchService,
		public rss: RecentSearchesService,
		public qs: QueryParamsService,
		public ols: OptionsListService,
		private router:Router
	) {

		const l = `page-quicksearch constructor() - `
		// Getting rid of quicksearch:

		this.router.navigate([this.ms.makeRoute({ path: 'similarname', caller: l })])

		//this.ms.setEndpoint("quicksearch")
		// this.qs.resetQP("quicksearch", l);

	}

	async ngOnInit(): Promise<void> {
		const l = `pageQuickSearch ngOnInit() - `
		this.router.navigate([this.ms.makeRoute({ path: 'similarname', caller: l })])
		return
		/*
		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		// console.log(`${l}queryParams = `, this.qs.getQP())

		// if coming back to this page from results page
		// remove facets
		this.qs.keepOnlySearchParams();

		this.buildStuff();
		this.getCounts();
		this.getNews();

		this.bricks.searchBy = this.qs.getQP("searchBy") || "brandName"
		*/
	}
	get windowWidth(): number {
		return window.innerWidth
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	buildStuff() {
		this.buildOptions()
		this.buildBricksFromQP()
	}

	get parent(){
		return this
	}

	buildBricksFromQP() {
		const l = `page-quickSearch buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		// console.log(`${l}type=${typeof qpAsStructureString } - qpAsStructure = `, qpAsStructureString)

		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			const parsedStructure = JSON.parse(qpAsStructureString);
			this.bricks = Object.assign(this.bricks, asStructure2bricks(parsedStructure))
			this.bricks.v = parsedStructure.bricks[0]?.value; // transforming brandName/number/applicant/combined into the generic "v"
		}
	}

	get_news_for_lang(value?): string {
		return value ? (value[this.ms.lang] || value["en"]) : ''
	}

	buildOptions() {
		this.optionsSearchBy = [
			{ value: "brandName", label: "" },
			{ value: "applicant", label: "" },
			{ value: "number", label: "" },
			{ value: "combined", label: "" }
		]
	}



	async getNews() {

		const l: string = `quickSearchComponent getNews() - `

		try {
			let news: DBNews[] = await this.ss.getDbNews()

			this.news = news.slice(0, 1).map(n => {
				n["news_date"] = this.ms.dateFormatterHuman.format(new Date(n["news_date"]));
				return n
			})
		}
		catch (err) {
			// console.log(`${l}Caught error : `, err)
		}
	}

	

	get icon_save(): string { // Jer : dafuk?
		return `<span class="save"> </span>`
	}

	get isComplexQuery(): boolean {
		return /( AND | OR )/.test(this.bricks.v || "")
	}

	get welcomeSpeech(): string {
		let office = this.ms.officeCC
		// the search engine is configured for an office
		if (office) {
			const welcome_msg = 'page_quick_search.welcome_speech_peroffice' + (["WHO", "LISBON", "6TER"].includes(office) ? `_${office}` : '');

			return this.ms.translate(welcome_msg)
				.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
				.replace('{c}', this.ms.translate('designation.' + office))
				.replace('{l}', this.ms.translate('office.' + office))
		}

		return this.ms.translate('page_quick_search.welcome_speech')
			.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
			.replace('{d}', this.dataSourcesCount)
	}


	get hint_advancedSearch() {

		let toReturn = this.ms.translate("page_quick_search.hint_advancedSearch")
			.replace("<a>", `<a href="/${this.ms.lang}/advancedsearch">`)
		return toReturn
	}


	suggest($event: KeyboardEvent) {

		const l: string = `quickSearchComp suggest() - `

		// console.log(`${l}$event = `, $event)

		this.ols.suggest($event, this.qs.getQP("by"))
	}

	onSearchByClicked(value) {
		this.bricks.searchBy = value;
		this.ols.suggestions = [];
		this.qs.setQP("searchBy", value)
	}

	onSuggestSelect(value) {
		const l: string = `quickSearchComp onSuggestSelect() - `
		// remove the highlight tag <b></b>
		value = value.replace(/<[^>]*>?/gm, '');
		this.qs.setQP("v", value)
	}

	processLinks(e) {

		const l = `pageQuickSearch processLinks() - `

		// https://stackoverflow.com/questions/55350647/how-to-use-routerlink-with-innerhtml

		const element: HTMLElement = e.target;
		if (element.nodeName === 'A') {
			e.preventDefault();
			const link = element.getAttribute('href');

			// console.log(`${l}Navigating to '${link}'`)

			this.ms.router.navigate([link]);
		}
	}

	get canSearch() {
		return canSearch(this.bricks.v)
	}

	async search() {
		const l: string = `pageQuickSearch search() - `
		// console.log(`${l}`);
		if (!this.canSearch) return;

		// a few transforms before searching

		for (const key of ["brandName", "applicant", "number"]) delete this.bricks[key];

		let asStructure = {};

		if (this.bricks.searchBy === "combined") {

			const value = this.bricks.v.trim();

			asStructure = {
				"boolean": "OR",
				"bricks": [
					{
						"key": "brandName",
						value,
						"strategy": "Simple"
					},
					{
						"key": "applicant",
						value,
						"strategy": "Simple"
					},
					{
						"key": "number",
						value,
						"strategy": "Either"
					},
				]
			}

		} else {
			this.bricks[this.bricks.searchBy] = this.bricks.v.trim(); // Transforming the generic "v" into brandName, applicant or number
			asStructure = bricks2AsStructure(this.bricks)

		}

		this.qs.setQP("asStructure", JSON.stringify(asStructure))
		this.qs.setQP("fg", "_void_")

		this.ms.setLoading(true)

		// this will navigate to results and set the query params and trigger
		// the activatedRoute change with queryParams and launch the search
		// results page and launch the search
		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		await this.qs.queryParamsObjectToUrl(route)
	}


}

