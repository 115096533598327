export const countriesByContinent = {

	// africa
	"AFR": ["DZ", "AO", "BW", "BI", "CM", "CV", "CF", "TD", "KM", "YT",
		"CG", "CD", "BJ", "GQ", "ET", "ER", "DJ", "GA", "GM", "GH",
		"GN", "CI", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR",
		"MU", "MA", "MZ", "NA", "NE", "NG", "GW", "RE", "RW", "SH",
		"ST", "SN", "SC", "SL", "SO", "ZA", "ZW", "SS", "EH", "SD",
		"SZ", "TG", "TN", "UG", "EG", "TZ", "BF", "ZM"],

	// antartica
	"ANT": ["AQ", "BV", "GS", "TF", "HM"],

	// asia
	"ASI": ["AF", "AZ", "BH", "BD", "AM", "BT", "IO", "BN", "MM", "KH",
		"LK", "CN", "TW", "CX", "CC", "CY", "GE", "PS", "HK", "IN",
		"ID", "IR", "IQ", "IL", "JP", "KZ", "JO", "KP", "KR", "KW",
		"KG", "LA", "LB", "MO", "MY", "MV", "MN", "OM", "NP", "PK",
		"PH", "TL", "QA", "RU", "SA", "SG", "VN", "SY", "TJ", "TH",
		"AE", "TR", "TM", "UZ", "YE", "XE", "XD", "XS"],

	// north america
	"NAM": ["AG", "BS", "BB", "BM", "BZ", "VG", "CA", "KY", "CR", "CU",
		"DM", "DO", "SV", "GL", "GD", "GP", "GT", "HT", "HN", "JM",
		"MQ", "MX", "MS", "AN", "CW", "AW", "SX", "BQ", "NI", "UM",
		"PA", "PR", "BL", "KN", "AI", "LC", "MF", "PM", "VC", "TT",
		"TC", "US", "VI"],

	// south america
	"SAM": ["AR", "BO", "BR", "CL", "CO", "EC", "FK", "GF", "GY", "PY",
		"PE", "SR", "UY", "VE"],
	// europe
	"EUR": ["AL", "AD", "AZ", "AT", "AM", "BE", "BA", "BG", "BY", "HR",
		"CY", "CZ", "DK", "EE", "FO", "FI", "AX", "FR", "GE", "DE",
		"GI", "GR", "VA", "HU", "IS", "IE", "IT", "KZ", "LV", "LI",
		"LT", "LU", "MT", "MC", "MD", "ME", "NL", "NO", "PL", "PT",
		"RO", "RU", "SM", "RS", "SK", "SI", "ES", "SJ", "SE", "CH",
		"TR", "UA", "MK", "GB", "GG", "JE", "IM"],
	// oceana
	"OCE": ["AS", "AU", "SB", "CK", "FJ", "PF", "KI", "GU", "NR", "NC",
		"VU", "NZ", "NU", "NF", "MP", "UM", "FM", "MH", "PW", "PG",
		"PN", "TK", "TO", "TV", "WF", "WS"],

	// multi-national / international

	"XXX": ["WO", "EM", "WHO", "BX", "LISBON", "SIXTER", "AP", "OA"]

}
