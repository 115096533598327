import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { OptionsListService } from 'src/app/_services/options-list.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { deepClone, bricks2AsStructure, asStructure2bricks } from 'src/app/utils';

@Component({
	selector: 'page-explore',
	templateUrl: './page-explore.component.html',
	styleUrls: ['./page-explore.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageExploreComponent implements OnInit {

	public bricks

	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		public ols: OptionsListService) {

		const l = `pageExplore constructor - `

		this.ms.setEndpoint("explore")
		// console.log(`${l}queryParams.explore = `, deepClone(this.qs.queryParams.explore))

		this.bricks = this.defaultBricks;
	}

	get defaultBricks() {
		return {
			brandName: "",
			brandNameStrategy: "Exact",

			applicant: "",
			applicantStrategy: "Exact",

			representative: "",
			representativeStrategy: "Exact",

			goodsServices: "",
			goodsServicesStrategy: "Exact",
		}
	}

	ngOnInit(): void {
		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		this.ms.graphsSelection.reset() // reset graph selection
		this.ms.graphsRange.reset() // reset graph range

		this.buildBricksFromQP()

		// remove previosly selected facets
		// this.qs.reduceToSearchParams(); --> Jer : do we want to do that? We lose the previously selected facets
	}

	ngOnDestroy() {
		// this.autocompleteSubscription?.unsubscribe() --> Jer : probably dead code
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	buildBricksFromQP() {
		const l = `page-explore buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		// console.log(`${l}type=${typeof qpAsStructureString } - qpAsStructureString = `, qpAsStructureString)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {

			// console.log(`${l}parsed asStructure = `, JSON.parse(qpAsStructureString))

			this.bricks = Object.assign(this.bricks, asStructure2bricks(JSON.parse(qpAsStructureString)))
		}
	}

	drawGraphs() { // another name for what we usually call search() in other components

		const l: string = `PageExplore drawGraphs() - `

		this.ms.setLoading(true)

		// console.log(`${l} Navigating to '${route}'`);

		this.qs.setQP("asStructure", JSON.stringify(bricks2AsStructure(this.bricks)));

		// this will navigate to results and set the query params and trigger
		// the activatedRoute change with queryParams and launch the search
		// results page and launch the search
		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'visu', caller: l }) // "/en/explore/visu"

		// update the _ so that the result after refinement is not read from browser cache
		this.qs.setQP("_", Date.now())

		this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {
		const l = `page-explore doReset - `
		this.bricks = this.defaultBricks;
	}
}
