import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { VisuComponent } from './visu/visu.component';

@Component({
	selector: 'page-visu',
	templateUrl: './page-visu.component.html',
	styleUrls: ['./page-visu.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageVisuComponent implements OnInit {

	contentComponent: any;

	ngOnInit() {
		this.contentComponent = VisuComponent; // Jer : what in the absolute fuck
	}



}
