import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import html2pdf from "html2pdf.js"
import { wipoImage } from 'src/app/_imports/wipoImage';
import { Doc2fieldsService } from 'src/app/_services/doc2fields.service';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';
import { GBDSolrResponse } from 'src/app/interfaces';
import { decrypt } from 'src/app/utils';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'page-details',
	templateUrl: './page-details.component.html',
	styleUrls: ['./page-details.component.css'],
	encapsulation: ViewEncapsulation.None,

})


export class PageDetailsComponent implements OnInit {

	public doc: any;
	public fields: any;
	public images: any[];
	public images_blob_th: any = {}
	public images_blob_big: any = {}


	public isMock: boolean
	private urlchangeSubscription: any;
	public ev1:boolean = false;
	public ev2:boolean = false;
	public ev3:boolean = false;
	public ev4:boolean = false;
	public ev5:boolean = false;
	public ev6:boolean = false;
	public ev7:boolean = false;

	public isExporting:boolean = false; // When true, hides nav, expands truncated paragraphs, removes "show more"

	constructor(public ss: SearchService,
		private ar: ActivatedRoute,
		private lc: Location,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public qs: QueryParamsService,
		private d2f: Doc2fieldsService,
	) { }

	async ngOnInit(): Promise<void> {

		const l: string = `PageDetailsComponent ngOnInit() - `

		/*
			OK so.
			I'm trying to reuse the page-details component to display and export full documents from a report, because there's already all the logic to display and export full docs, I don't want to duplicate all that..
			When we click "Export this report to PDF" from the reports we land here.

			Problem :
			How do I know we land here exporting a list of documents from the reports page, and not simply displaying one full document? From the URL :endpoint of course.
			But. The endpoint is /reports in the URL, however it's not possible to get it using 'ar.snapshot.params.endpoint' because it's hardcoded as '/reports' in the route, it's not '/:endpoint'.
			Why is that? Because the generic route ':lang/:endpoint/export' loads 'page-results/export.module' and I don't want this one, I want the 'page-details', so I have to shortcut the routes by hardcoding this one.

			So how can I know we're coming from /reports?
			By looking at the queryParams. If there's 'reportName' then I know we come from reports. Not elegant but it works.

			Update : we're only exporting the simple reports list for now, so I'm leaving this work here for later
		*/

		this.ms.setEndpoint(this.ar.snapshot.params.endpoint)

		this.ms.setLoading(true, l)
		this.urlchangeSubscription = this.ar.queryParams.subscribe(
			this.fetchFullDoc.bind(this)
		)
	}

	get captchaURL(): string {
		return this.ms.environment.backendUrl + '/captcha?t=' +  localStorage.getItem("gbd.hashSearches")
	}


	async onVerify(ev: any, place_holder_name) {
		if (ev.type === 'verified') {
			this[place_holder_name] = true
		}
	}		

	converBase64toBlob = (base64: string): any => {
		const contentType = "application/pdf";
		const sliceSize = 512;
		const byteCharacters = window.atob(base64); //method which converts base64 to binary
		const byteArrays = [
		];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, {
			type: contentType
		}); //statement which creates the blob
		return blob;
	}

	async exportAsPDF() {

		// path: `:lang/:endpoint/brand/:st13/export`
		// window.open(`/${this.ms.lang}/brand/${this.ar.snapshot.params.st13}/export?format=pdf`)

		const l = `exportAsPDF() - `

		this.ms.isLoading = true;
		this.isExporting = true;
		
		// Grrr I don't know why I need a hack to force booleans to be true. I need it, because it expands collapsed fields (Nice classification...) and removes "Show less" while exporting the PDF
		await new Promise( r => setTimeout(r, 100));
		
		const element = document.getElementById('pdfExport');
		var clonedElement = <Element>element.cloneNode(true);
		clonedElement.setAttribute('class', clonedElement.getAttribute('class') + ' isExporting')

		const opt = {
			margin: [2.4, 1.4, 1.4, 1.4],
			filename: 'GBD-' + this.ar.snapshot.params.st13 + `_${formatDate(new Date(), 'yyyyMMdd', 'en_US')}.pdf`,
			image: { type: 'jpeg', quality: 0.75 },
			html2canvas: { scale: 2, letterRendering: true, useCORS: true },
			pagebreak: { avoid: 'ul' },
			jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' }
		};
		
		// Below : give some time for ms.setLoading to register to true. This will expand all the collapsed fields (super long classifications etc) in the page, so they'll be open during the PDF export
		// await new Promise(r => setTimeout(r, 50000));

		await html2pdf()
			.set(opt)
			.from(clonedElement)
			.toPdf()
			.get('pdf')
			.then(pdf => { // That's a thenable, not a Promise
				let totalPages = pdf.internal.getNumberOfPages();

				for (let i = 1; i <= totalPages; i++) {
					pdf.setPage(i);
					pdf.setFontSize(8);
					pdf.setTextColor(150);
					pdf.text(
						i + '/' + totalPages,
						pdf.internal.pageSize.getWidth() / 2 - 1,
						pdf.internal.pageSize.getHeight() - 0.8
					)
					pdf.setFontSize(10);
					pdf.text(
						'WIPO',
						pdf.internal.pageSize.getWidth() / 2 - 1.5,
						0.8
					)
					// addImage method https://artskydj.github.io/jsPDF/docs/module-addImage.html
					pdf.addImage(wipoImage, 'png', 0.5, 0.25, 4, 2)
				}
				// window.open(pdf.output('bloburl'), '_blank');
			})
			.save();

		this.ms.setLoading(false)
		this.isExporting = false;
		
	}

	async fetchFullDoc(): Promise<void> {
		const l = `pageDetails fetchFullDoc() - `

		// console.log(`${l}`)

		const st13 = this.ar.snapshot.params.st13
		let office = null
		if (this.ar.snapshot.params.office){
			office = this.ar.snapshot.params.office.replace('IPO-', '')
		}

		let res: GBDSolrResponse;
		
		try {
			res = await this.ss.getDocumentDetails(st13, office);

			res = decrypt(res);

			// console.log(`${l}RES = `, res)
		}
		catch (err) {

			this.ms.setSearchError(err)

			switch (err.status) {
				case 404:
					// cannot find the doc
					this.ms.router.navigate([this.ms.lang, "notfound"], { replaceUrl: true })
					break
			}
			return
		}

		this.isMock = !!res.isMock
		this.doc = res.response.docs[0];
		this.images = this.doc.logos;
		for(let img of this.images){
			this.images_blob_th[img] = await this.ms.getBase64ImageFromUrl(this.get_th_image(img)) as string
			this.images_blob_big[img] = await this.ms.getBase64ImageFromUrl(this.get_big_image(img)) as string
			if (this.images_blob_big[img] == ''){
				this.images_blob_big[img] = this.images_blob_th[img] 
			}
		}
		this.buildStuff();

		this.ms.setLoading(false, l)
	}

	get_collection(collection, st13){

		if(['wotm', 'sixtertm', 'lisbontm'].includes(collection)){
			switch(st13.substring(2,4)){
				case '80':
					collection = 'wo6ter'
					break;
				case '81':
					collection = 'woao'
					break;
				case '60':
					collection = 'woptm'
					break;
				default:
					collection = 'wotm'
					break;
			}
		}
		return collection
	}

	get_th_image(img:string):string{
		const st13: string = this.doc.st13;
		const collection: string = this.get_collection(this.doc.registrationOfficeCode.toLowerCase() + 'tm', st13)
		let imagePath = `/brands/${collection}/${st13}/${img}-th.jpg`
		return environment.imgBaseUrl + imagePath
	}

	get_big_image(img:number):string {
		const st13: string = this.doc.st13;
		const collection: string = this.get_collection(this.doc.registrationOfficeCode.toLowerCase() + 'tm', st13)
		let imagePath = `/brands/${collection}/${st13}/${img}-hi.png`
		return environment.imgBaseUrl + imagePath
	}

	ngOnDestroy(): void {
		this.ms.unsetSearchError()
		this.urlchangeSubscription.unsubscribe();
	}

	buildStuff() {


		const l=``;

		this.d2f.buildFields(this.doc);

		// console.log(`${l}this.doc = `,this.doc)

		this.fields = this.d2f.fields;
		if (this[`_link_${this.doc.registrationOfficeCode}`]){
			let tmp = this[`_link_${this.doc.registrationOfficeCode}`](this.doc)
			this.doc.link2item = tmp[0]!= '' ? tmp[0] : undefined
			this.doc.link2office = tmp[1]!= '' ? tmp[1] : undefined

		}


		// expand doc with helpers to show/hide content blocks
		// 03.names-addresses
		this.doc.hasApplicants = !!this.fields.applicants.length;
		this.doc.hasRepresentatives = !!this.fields.representatives.length;
		this.doc.hasCorrespondence = !!this.doc.correspondence;
		this.doc.hasNamesAddresses = this.doc.hasApplicants || this.doc.hasRepresentatives || this.doc.hasCorrespondence;
		// 04.classification
		this.doc.hasNiceClassification = !!this.fields.goodsServicesClassification.terms.length;
		this.doc.hasNationalClassification = !!this.fields.nationalGoodsServicesClassification.terms.length;
		this.doc.hasUnclassifiedClassification = !!this.fields.goodsServicesUnclassified.content;
		this.doc.hasImageClassification = !!this.fields.markImageDetails.classification?.content;
		this.doc.hasClassification = this.doc.hasNiceClassification
									|| this.doc.hasNationalClassification
									|| this.doc.hasUnclassifiedClassification
									|| this.doc.hasImageClassification;
	}

	openNewTab(): void {
		window.open(`${this.ms.environment.appUrl}/${this.ms.lang}/brand/${this.doc.st13}`, "_blank")
	}

	backToResults(): void {
		this.lc.back()
	}

	get windowWidth(): number {
		return window.innerWidth
	}



	async findSimilarImages(url: string) {
		
		const l = `pageDetails findSimilarImages() - `
		let base64 = await this.ms.getBase64ImageFromUrl(url)
		
		sessionStorage.setItem("gbd.ms.bases64", `["${base64}"]`)
		this.ms.bases64 = [base64];
		this.ms.bases64resized = [base64];


		const route = this.ms.makeRoute({ path: 'similarlogo', caller: l })
		this.qs.queryParamsObjectToUrl(route)
	}

	get currentPage(): number {
		// returns 0 for page 1, 15 for page2, etc. Multiples of 15
		return +(this.qs.getQP("start") || 0); // should be 0, 15, 30....
	}

	get isNewTab(): boolean {

		// When opening in a new tab, we strip off all query parameters. Therefore, by looking if there are any query parameters, we can know if we are in a new tab, and hide the "new tab" button.

		return !location.search.length
	}

	async prevDoc(): Promise<void> {

		const l = `pageDetails prevDoc() - `

		let st13 = ''

		// if previous record exitst in resultset
		if (this.ss.searchResult.hasPrevRecord()) {
			// not changing the '_' params, so we need to change something else
			this.qs.setQP('i', +this.qs.getQP('i') - 1)

			// set cursor to previous doc
			st13 = this.ss.searchResult.getPrevRecord()
		}

		// if not, got to previous page
		else if (this.ss.searchResult.hasPrevPage()) {
			// set the start param to go to prev page
			this.qs.setQP("start", this.currentPage - +(this.ps.getPref('rows')))
			this.qs.setQP('i', +this.ps.getPref('rows') - 1)

			let payload: any = this.qs.toPayload()
			let cid: string = this.qs.getQP('_')

			// relaunch the query without cid param
			let results = await lastValueFrom(this.ss.search(payload));

			results = decrypt(results); // Sends back the data as is if can't decrypt

			// reset the searchResult
			this.ss.searchResult.init(results, cid)

			// set cursor to last document
			st13 = this.ss.searchResult.getLastRecord()
		}

		if (st13) {
			const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `brand/${st13}`, caller: l })

			return this.qs.queryParamsObjectToUrl(route, true)
		}
	}

	async nextDoc(): Promise<void> {
		const l = `pageDetails nextDoc() - `

		let st13: string = null

		if (this.ss.searchResult.hasNextRecord()) {

			// not changing the '_' params, so we need to change something else
			this.qs.setQP('i', +this.qs.getQP('i') + 1)

			st13 = this.ss.searchResult.getNextRecord()

		} else if (this.ss.searchResult.hasNextPage()) {

			// set the start param to go to next page
			this.qs.setQP("start", this.currentPage + +(this.ps.getPref('rows')))
			this.qs.setQP('i', 0)

			let payload: any = this.qs.toPayload()
			let cid: string = this.qs.getQP('_')

			// relaunch the query without cid param
			try {
				let results = await lastValueFrom(this.ss.search(payload));

				results = decrypt(results); // Sends back the data as is if can't decrypt

				// reset the searchResult
				this.ss.searchResult.init(results, cid)

				// set cursor to first document
				st13 = this.ss.searchResult.getFirstRecord()
			}
			catch (err) {
				
				this.ms.setSearchError(err)
			}
		}

		if (st13) {
			const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `brand/${st13}`, caller: l })
			return this.qs.queryParamsObjectToUrl(route, true)
		}
	}

	// -------------------------------
	// links to office where available
	// -------------------------------
	_link_VC(doc): string[] {
		return ['', ' https://cipo.gov.vc/']
	}

	_link_AU(doc): string[] {
		return [
			`https://search.ipaustralia.gov.au/trademarks/search/view/${this.doc.applicationNumber}/`,
			`https://search.ipaustralia.gov.au/trademarks/search/view/${this.doc.applicationNumber}/`,
		]
	}
	_link_CA(doc): string[] {
		let [id, key] = this.doc.applicationNumber.split('-') // 1234 or 1234-01
		key = key || '00'
		while (id.length < 7) id = "0" + id;
		return [
			`https://ised-isde.canada.ca/cipo/trademark-search/${id}`,
			`https://ised-isde.canada.ca/cipo/trademark-search/${id}`
		]
	}
	_link_CH(doc): string[] {
		return [
			`https://www.swissreg.ch/srclient/en/tm/${doc.applicationNumber}`,
			`https://www.swissreg.ch/srclient/en/tm/${doc.applicationNumber}`
		]
	}
	_link_CU(doc): string[] {
		return [
			`http://wiposearch.ocpi.cu/wopublish-search/public/detail/trademarks?id=${doc.applicationNumber.replaceAll('/', '')}`,
			`http://wiposearch.ocpi.cu/wopublish-search/public/detail/trademarks?id=${doc.applicationNumber.replaceAll('/', '')}`
		]
	}
	_link_DE(doc): string[] {
		return [
			`https://register.dpma.de/DPMAregister/marke/register/${doc.applicationNumber}/DE`,
			`https://register.dpma.de/DPMAregister/marke/register/${doc.applicationNumber}/DE`
		]
	}
	_link_DK(doc): string[] {
		return [
			`http://onlineweb.dkpto.dk/pvsonline/Varemaerke?action=107&sagID=${doc.applicationNumber}`,
			`http://onlineweb.dkpto.dk/pvsonline/Varemaerke?action=107&sagID=${doc.applicationNumber}`
		]
	}
	_link_EE(doc): string[] {
		return [
			`http://andmebaas.epa.ee/avalik/#/trademarks?nr=${doc.applicationNumber}`,
			`http://andmebaas.epa.ee/avalik/#/trademarks?nr=${doc.applicationNumber}`
			]
	}
	_link_EM(doc): string[] {
		return [
			`https://www.tmdn.org/tmview/#/tmview/detail/${doc.st13}`,
			`https://www.tmdn.org/tmview/#/tmview/detail/${doc.st13}`
		]
	}
	_link_ES(doc): string[] {
		return [
			`http://consultas2.oepm.es/ceo/jsp/busqueda/consultaExterna.xhtml?numExp=${doc.applicationNumber}`,
			`http://consultas2.oepm.es/ceo/jsp/busqueda/consultaExterna.xhtml?numExp=${doc.applicationNumber}`
		]
	}
	_link_FR(doc): string[] {
		return [
			`https://data.inpi.fr/marques/FR${doc.applicationNumber}`,
			`https://data.inpi.fr/marques/FR${doc.applicationNumber}`
		]
	}
	_link_IL(doc): string[] {
		return [
			`http://www.trademarks.justice.gov.il/TradeMarksWebSiteUI/TrademarksSearch/ReportView.aspx?tmcaseid=${doc.extra.caseid}`,
			`http://www.trademarks.justice.gov.il/TradeMarksWebSiteUI/TrademarksSearch/ReportView.aspx?tmcaseid=${doc.extra.caseid}`
		]
	}
	_link_IS(doc): string[] {
		return [
			`https://www.isipo.is/trademark/${doc.applicationNumber}`,
			`https://www.isipo.is/trademark/${doc.applicationNumber}`
		]
	}
	_link_IT(doc): string[] {
		return ['', `https://www.uibm.gov.it/bancadati/Number_search/type_url?type=wpn`]
	}
	_link_OA(doc): string[] {
		return ['', `http://oapi.int/index.php/en/`]
	}
	_link_AP(doc): string[] {
		let parsed_el = doc.applicationNumber.split('/')
		parsed_el = parsed_el.join('')
		return [
			`http://regionalip.aripo.org/wopublish-search/public/detail/trademarks?id=${parsed_el}`,
			`http://regionalip.aripo.org/wopublish-search/public/detail/trademarks?id=${parsed_el}`
		]
	}
	_link_ST(doc): string[] {
		return ['', `http://senapi-stp.net/spip.php?rubrique1`]
	}
	_link_TT(doc): string[] {
		return ['', `https://ipo.gov.tt/`]
	}
	_link_LK(doc): string[] {
		return ['', `https://www.nipo.gov.lk/web/index.php?lang=en`]
	}
	_link_BZ(doc): string[] {
		return ['', `https://www.belipo.bz/`]
	}
	_link_JP(doc): string[] {
		return ['', `https://www.j-platpat.inpit.go.jp/`]
	}
	_link_QA(doc): string[]{
		return ['', `https://www.moci.gov.qa/en/`]
	}
	_link_KG(doc): string[] {
		return [
			`http://base.patent.kg/index.php?action=search_list&registration_nbr=${this.doc.registrationNumber}`,
			`http://base.patent.kg/index.php?action=search_list&registration_nbr=${this.doc.registrationNumber}`
		]
	}
	_link_KR(doc): string[] {
		return [
			`http://link.kipris.or.kr/link/main/Biblio.jsp?reg_key=qSyPqyw0POQy30NoItNS5Q%3D%3D&APPLNO=${this.doc.applicationNumber}`,
			`http://link.kipris.or.kr/link/main/Biblio.jsp?reg_key=qSyPqyw0POQy30NoItNS5Q%3D%3D&APPLNO=${this.doc.applicationNumber}`
	]
	}
	_link_KZ(doc): string[] {
		return [
			`https://gosreestr.kazpatent.kz/Trademark/Details?docNumber=${this.doc.extra.id}`,
			`https://gosreestr.kazpatent.kz/Trademark/Details?docNumber=${this.doc.extra.id}`
		]
	}
	_link_MD(doc): string[] {
		return [
			`http://www.db.agepi.md/marcireprezentanti/Details.aspx?idapply=${this.doc.applicationNumber}`,
			`http://www.db.agepi.md/marcireprezentanti/Details.aspx?idapply=${this.doc.applicationNumber}`
		]
	}
	_link_MK(doc): string[] {
		return [
			`http://ippo.gov.mk/Search/TradeMarkSearchDetails.aspx?appnr=${this.doc.applicationNumber}`,
			`http://ippo.gov.mk/Search/TradeMarkSearchDetails.aspx?appnr=${this.doc.applicationNumber}`
		]
	}
	_link_MX(doc): string[] {
		// MX502021002661860 -> RM202102661860
		let appKey = this.doc.st13
		appKey = appKey.replace('MX50', 'RM')
		appKey = appKey.substring(0, 6) + appKey.substring(7)

		return [
			`https://marcia.impi.gob.mx/marcas/search/details/${appKey}`,
			`https://marcia.impi.gob.mx/marcas/search/details/${appKey}`
		]
	}
	_link_NZ(doc): string[] {
		return ['',
			`https://app.iponz.govt.nz/app/Extra/Default.aspx?op=EXTRA_tm_qbe&fcoOp=EXTRA__Default&directAccess=true`]
	}
	_link_RS(doc): string[] {
		return [
			`https://reg.zis.gov.rs/regis/detail.php?entity=mark&lang=en&file_nbr=${this.doc.applicationNumber}/`,
			`https://reg.zis.gov.rs/regis/detail.php?entity=mark&lang=en&file_nbr=${this.doc.applicationNumber}/`
		]
	}
	_link_SM(doc): string[] {
		return [
			`https://www.pa.sm/usbm/wopublish/wopublish-search/public/detail/trademarks?id=${this.doc.applicationNumber.replaceAll('/', '')}`,
			`https://www.pa.sm/usbm/wopublish/wopublish-search/public/detail/trademarks?id=${this.doc.applicationNumber.replaceAll('/', '')}`
		]
	}
	_link_US(doc): string[] {
		return [
			`http://tsdr.uspto.gov/#caseNumber=${this.doc.applicationNumber}&caseType=SERIAL_NO&searchType=statusSearch`,
			`http://tsdr.uspto.gov/#caseNumber=${this.doc.applicationNumber}&caseType=SERIAL_NO&searchType=statusSearch`
		]
	}
	_link_UA(doc): string[] {
		return [
			`https://sis.ukrpatent.org/uk/search/detail/${this.doc.extra.id}/`,
			`https://sis.ukrpatent.org/uk/search/detail/${this.doc.extra.id}/`
		]
	}
	_link_UY(doc): string[] {
		return [
			`http://rad.miem.gub.uy/pamp/mark/show?seq=MA&type=M&series=1&number=${this.doc.applicationNumber}/`,
			`http://rad.miem.gub.uy/pamp/mark/show?seq=MA&type=M&series=1&number=${this.doc.applicationNumber}/`
		]
	}
	_link_WHO(doc): string[] {
		return [
			`https://extranet.who.int/tools/inn_global_data_hub/INN_Hub.php?code=${this.doc.extra.code}/`,
			`https://extranet.who.int/tools/inn_global_data_hub/INN_Hub.php?code=${this.doc.extra.code}/`
		]
	}

	_link_WO(doc): string[] {
		if(this.doc.gbdStatus != 'Pending') {
			return [
				`https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${this.doc.registrationNumber}`,
				`https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${this.doc.registrationNumber}`
			]
		}
		return ['', 'https://www3.wipo.int/madrid/monitor']
	}

	_link_BR(doc): string[]{
		return [
			`https://busca.inpi.gov.br/pePI/servlet/MarcasServletController?Action=detail&CodPedido=${this.doc.applicationNumber}`,
			`https://busca.inpi.gov.br/pePI/servlet/MarcasServletController?Action=detail&CodPedido=${this.doc.applicationNumber}`
	]}
	
	_link_GB(doc): string[]{
		return [
			`https://trademarks.ipo.gov.uk/ipo-tmcase/page/Results/1/${this.doc.applicationNumber}`,
			`https://trademarks.ipo.gov.uk/ipo-tmcase/page/Results/1/${this.doc.applicationNumber}`
		]
	}

	_link_SIXTER(doc): string[] {
		return [
			`https://6ter.wipo.int/result-detail?offset=0&limit=1&keywords=%7B%22offset%22:0,%22limit%22:25,%22searchText%22:%22${this.doc.registrationNumber}%22,%22sortBy%22:%22KEY%22,%22searchFields%22:%5B%5D%7D&queryType=STRUCT&sortBy=KEY/`,
			`https://6ter.wipo.int/result-detail?offset=0&limit=1&keywords=%7B%22offset%22:0,%22limit%22:25,%22searchText%22:%22${this.doc.registrationNumber}%22,%22sortBy%22:%22KEY%22,%22searchFields%22:%5B%5D%7D&queryType=STRUCT&sortBy=KEY/`		
		]	
}
	_link_LISBON(doc): string[] {
		return [
			`https://www.wipo.int/ipdl-lisbon/map-result-detail?key=${this.doc.registrationNumber}&lang=en`,
			`https://www.wipo.int/ipdl-lisbon/map-result-detail?key=${this.doc.registrationNumber}&lang=en`
		]
	}
	_link_RO(doc): string[]{
		return [
			`https://api.osim.ro:8443/tm-registry/detail/trademark.htm?idappli=${this.doc.applicationNumber}`,
			`https://api.osim.ro:8443/tm-registry/detail/trademark.htm?idappli=${this.doc.applicationNumber}`
		]
	}
}
