import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { MechanicsService } from '../_services/mechanics.service';
import { QueryParamsService } from '../_services/queryParams.service';
import { SearchService } from '../_services/search.service';
import { ReportsService } from '../_services/reports.service';
import { SearchSolrResponse } from '../interfaces';
import { decrypt, deepClone, setPrevEnpoint } from '../utils';
import { PreferencesService } from '../_services/preferences.service';

@Component({
	selector: 'hoc-search',
	template: '',
})
export class HOCSearchComponent implements OnInit {

	@Input() bodyComponent: any; // = ResultsComponents (always...)
	@Input() page: string;      // = "export", "graph", "results"

	private urlchangeSubscription: any;
	private componentRef: any;
	private endpoint: string;

	constructor(
		private viewContainerRef: ViewContainerRef,
		private ar: ActivatedRoute,
		private ms: MechanicsService,
		private qs: QueryParamsService,
		private rs: ReportsService,
		public ps: PreferencesService,
		private ss: SearchService) {

		const l = `HOCSearchComponent constructor() - `

		// console.log(`${l}page=`, this.page)

		this.endpoint = this.ar.snapshot.params.endpoint;

		this.ms.setEndpoint(this.endpoint);
		// this.qs.resetQP(this.endpoint, l); // Jer : did I do that? I'm lost

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC
	}

	ngOnInit() {

		const l = `HOCsearchComponent ngOnInit() - `

		// console.log(`${l}page=`, this.page)

		this.viewContainerRef.clear();
		
		this.componentRef = this.viewContainerRef.createComponent(this.bodyComponent);

		this.urlchangeSubscription = this.ar.queryParams.subscribe((urlQueryParams:Params) => {
			const l = `HOCSearchComponent urlchangeSubscription - `;
			//console.log(`03 - ${l}this.queryParams[${this.endpoint}] before urlToQueryParamsObject = `, deepClone(this.qs.getQP())) //  --> no office
			this.qs.urlToQueryParamsObject(urlQueryParams, this.endpoint);
			// console.log(`04 - ${l}this.queryParams[${this.endpoint}] after urlToQueryParamsObject= `, deepClone(this.qs.getQP())) //  --> office:['DZ'], OK
			this.search();
		});
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.urlchangeSubscription.unsubscribe();
	}


	async search(): Promise<any> {

		const l = `HOCsearchComponent search() - `

		//console.log(`${l}page=`, this.page) // "export"

		if (this.endpoint === 'reports') {  // REPORTS SPECIAL TREATMENT

			let reportName = this.qs.getQP('reportName')
			await this.rs.loadData()

			this.rs.hydrate(reportName, true).subscribe({
				next: (searchResult: SearchSolrResponse) => {
					this.ms.setLoading(false);

					searchResult = decrypt(searchResult);
					
					if(this.ms.isLocalHost){
						console.log(`(localhost log only) ${l}searchResult = `, searchResult)
					}

					this.componentRef.instance.setSearchResult(searchResult, reportName);

					this.ss.observeResults();
				},
				error: (err: HttpErrorResponse) => {
					this.ms.setLoading(false)
					this.componentRef.instance.setSearchError(err);
				}
			})
			return
		}

		let payloadExclude = [];

		if (this.page === 'graphs') {
			payloadExclude = ['sort', 'rows', 'start']
		}

		let payload = await this.qs.toPayload(this.endpoint, payloadExclude); // Here, bases64 is attached to the payload (if need be)

		if (this.ms.supressFacets) {
			this.ms.supressFacets = false

			// Facet Groups : pre-determined facets (see api.yml). Avoids to have to pass each facet individually
			// Can then be overriden individually (fcstatus="Registered" etc)
			payload['fg'] = '_void_'
		}

		if (this.page == 'visu'){
			let view =  this.ps.getPref('chart', 'graphs')
			if(view == '')
				view = "status"
			payload['fg'] = `explore-${view}`
		}

		if (this.page === 'export') {

			// Ignore those passed parameters and override them
			payload['rows'] = 180
			payload['start'] = 0
			payload['fg'] = '_void_'
			payload['images'] = true // embed the base64 logos in response
			delete payload['format'] // unneeded param for backend
		}

		let cid: string = this.qs.getQP('_'); // cache id

		this.ms.unsetSearchError()
		this.ms.setLoading(true, `${l} on behalf of ${this.endpoint}/${this.page}`);

		// console.log(`128 - ${l}calling ss.search with payload = `, deepClone(payload))
		this.ss.search(payload, cid)?.subscribe({

			next: (solrResponseEncrypted: string) => {

				// console.log(`${l}searchResult before decryption = `, searchResult)

				const solrResponse: SearchSolrResponse = decrypt(solrResponseEncrypted); // If can't decrypt, returns the data as is 

				if(this.ms.isLocalHost){
					console.log(`(localhost log only) ${l}decrypted solrResponse = `, solrResponse)
				}

				// console.log(`${l}componentRef.instance = `, this.componentRef.instance)
				// solr api error messages (go back to search form)
				
				//Save the previous endpoint unless it is explore. explore does a refresh on its own and deletes the previous endpoint
				// Fix for imagesimlarity/ advanced.
				if (this.ms.endpoint != 'explore')
					setPrevEnpoint(this.ms.endpoint)
				this.componentRef.instance.setSearchResult(solrResponse);
				this.ms.setLoading(false)
			},
			
			// lambda error message => display in results page
			error: (err: HttpErrorResponse) => {

				// console.log(`${l}CAUGHT ERROR = `, err)

				this.componentRef.instance.setSearchError(err);
				this.ms.setLoading(false)
			}
		})
	}

}

