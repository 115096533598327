import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment"
import { finalize, scan, take, timer } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MechanicsService } from './mechanics.service';
import queryString from 'query-string';
import { SearchService } from './search.service';
import { deepClone } from '../utils';


@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	/*
	  We store reports as ST13s only (in LocalStorage). Then, the ST13 list gets sent to the server, which replies with full populated objects, stored in a second variable
  
	  {
		st13 : {
		  "report 01" : {
			st13s : ["FR502010003717840", "FR502010003717840" ],
			collapsed : true
		  },
		  "report 02" : {
			st13s : ["FR502010003717840", "FR502010003717840", "FR502010003717840"],
			collapsed : false
		  }
		},
		full : {
		  "report 01" : [ Full solrResponse 1, Full solrResponse 2 ],
		  "report 02" : [ Full solrResponse 3, Full solrResponse 4, Full solrResponse 5 ]
		}
	  };
	*/

	public reports: any = {
		st13: {},
		full: {}
	};

	public deletedReports: any = {
		st13: {},
		full: {}
	};

	public deletedReportsCountdowns = {
		/*
		  "report 01" : Observable
		*/
	}

	constructor(private http: HttpClient,
		private ms: MechanicsService,
		private ss: SearchService) {

	}

	deleteReport(reportName: string): void {

		this.deletedReports.st13[reportName] = this.reports.st13[reportName];
		this.deletedReports.full[reportName] = this.reports.full[reportName];

		delete this.reports.st13[reportName]
		delete this.reports.full[reportName]

		// Undo mechanism : 10 seconds countdown
		const seconds: number = 10
		this.deletedReportsCountdowns[reportName] = timer(0, 1000)
			.pipe(
				scan(acc => --acc, seconds),
				take(seconds),
				finalize(() => {
					// console.log(`finalize '${reportName}'`)
					this.doDeleteReport(reportName)
				})
			);

		this.saveToLS();
		this.syncToDyanmoDB()

	}

	undeleteReport(reportName: string): void {

		const l = `undeleteReport()`

		delete this.deletedReportsCountdowns[reportName];

		this.reports.st13[reportName] = this.deletedReports.st13[reportName];
		this.reports.full[reportName] = this.deletedReports.full[reportName];

		delete this.deletedReports.st13[reportName]
		delete this.deletedReports.full[reportName]

		this.saveToLS();
		this.syncToDyanmoDB()
	}

	doDeleteReport(reportName: string) {

		const l = `doDeleteReport()`

		delete this.deletedReportsCountdowns[reportName];
		delete this.deletedReports.st13[reportName];
		delete this.deletedReports.full[reportName];

		this.saveToLS();
		this.syncToDyanmoDB()
	}

	// I am working on this --> Jer:who is?
	downloadReport(reportName: string): void {

		const l = `RS downloadReport() - `

		// console.log(`${l}reportName='${reportName}'`)


	}

	public get reportsNames(): string[] {
		const l: string = `RS reportsNames() - `

		return Object.keys(this.reports.st13)
	}

	hydrate(reportName: string, isEmbedImages: boolean = false): Observable<any> {

		const l = `RS hydrate() - `

		// Sending our list of ST13s to the server, receiving full objects

		// for (let el of $event.path) // console.log(`${l}el='${el}'`, el);

		let route = `${environment.backendUrl || ""}/docsidx`
		const st13: string[] = this.reports.st13[reportName].st13s;

		let payload = {
			st13,
			images: isEmbedImages // ask to embed the base64 logos in response
		};

		route = `${route}?${queryString.stringify(payload)}`;

		return this.http
			.get(route, { responseType: "text" }) // crypted
			.pipe(distinctUntilChanged(),
				debounceTime(500),
				catchError(err => {
					this.ms.setSearchError(err)
					return of(`${l}I caught: ${err.message}`)
				})
			)

	}

	async loadData(): Promise<void> {

		const l = `RS loadReports() - `

		this.reports.st13 = {}

		// First grab the remotes from remote
		let remoteReports; // Don't initialize it with {}, otherwise if(remoteReports) will always be true

		try {
			remoteReports = await this.ss.getPersist('reports');
			remoteReports = remoteReports[0]['payload']
		} catch (err) {
			//console.error(`${l}Found reports in DynamoDB, but could not decompress or parse them!`)
			remoteReports = {}
		}

		// console.log(`${l}Setting this.reports.st13 =`, deepClone(remoteReports))
		this.reports.st13 = remoteReports

		// Second get the local reports
		const localReportsStr: string = localStorage.getItem(`gbd.reports`);
		let localReports: JSON;

		if (localReportsStr) {
			try {
				localReports = JSON.parse(localReportsStr)
			} catch (err) {
				console.error("Found local reports in LocalStorage, but could not decompress or parse them!")
			}
		}

		if (localReports) {
			Object.assign(this.reports.st13, localReports || {})
		}

		// console.log(`${l}Final this.reports.st13, after merging local + Dynamo : `, this.reports.st13)

		// Finally persist locally all data
		this.saveToLS();

		// Then sync with DynamoDB
		await this.syncToDyanmoDB()

	}

	updateReport(reportName: string, st13s: string[]): void {

		const l = `RS updateReport() - `

		// console.log(`${l}reportName='${reportName}'`)

		this.reports.st13[reportName].st13s = st13s

		this.saveToLS()
		this.syncToDyanmoDB()
	}

	setReport(reportName: string, st13s: string[]): void {

		const l = `RS setReport() - `

		// console.log(`${l}219 this.reports = `, deepClone(this.reports)) // st13 = [], should be an object

		this.reports.st13[reportName] = this.reports.st13[reportName] || {};

		// console.log(`${l}st13s to merge = `, st13s);

		st13s = (this.reports.st13[reportName]?.st13s || [])
			.concat(st13s)
			.filter((value, index, self) => self.indexOf(value) === index) // add unique values to report

		// console.log(`${l}concatenated st13s are now=`, deepClone(st13s)); // LOG OK

		if (st13s.length > 180) {
			alert(this.ms.translate("page_reports.report_too_big"))
			st13s = st13s.slice(0, 180)
		}

		// console.log(`${l}reports BEFORE ADD this.reports = `, deepClone(this.reports))

		this.reports.st13[reportName] = {
			st13s,
			collapsed: true
		}

		// console.log(`${l}reports AFTER ADD this.reports = `, deepClone(this.reports));

		this.saveToLS()
		this.syncToDyanmoDB()
	}

	saveToLS(): void {
		// Save to Local storage the content of this.reports.st13
		const l = `RS saveToLS() - `

		const stringified = JSON.stringify(this.reports.st13);

		localStorage.setItem(
			`gbd.reports`,
			stringified
		);
	}

	async syncToDyanmoDB(): Promise<void> {
		// Save to DynamoDb storage the content of localstorage
		const l = `RS synctoDynamoDb() - `

		const localReportsStr: string = localStorage.getItem(`gbd.reports`);
		let localReports: string;

		if (localReportsStr) {
			try {
				localReports = JSON.parse(localReportsStr);

				let body = {
					payload: localReports,
					name: 'reports'
				}

				await this.ss.postPersist('reports', body)
			}
			catch (err) {
				console.error(`${l}DynamoDb sync error = `, err)
			}

		}
	}
}
