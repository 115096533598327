import { Component, ViewEncapsulation } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { chartDef } from 'src/app/interfaces';

@Component({
	selector: 'facets-explore',
	templateUrl: './comp-facets-explore.component.html',
	styleUrls: ['./comp-facets-explore.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class CompFacetsExplore {

	@Input() active: string; // Puts the name of the active chart in bold + underline in the left column
	@Input() chartsDefs: chartDef[]
	@Input() type: string;
	// emit an event when a facet is clicked
	// --> Emits to the parent component (pageGraphs) : (facetChoiceEvent)="changeChart($event, facet)"
	@Output() facetChoiceEvent = new EventEmitter<string>();

	// whether the side bar is collapsed
	public isCollapsed: boolean = false;

	constructor(public qs: QueryParamsService,
		public ms: MechanicsService) { }



	get title(): string{
		return this.ms.translate('page_visu.' + this.type).toUpperCase()
	}

	get anyFacetSelected(): boolean {
		for (let key of this.qs.keys) {
			if (/^fc/.test(key) && this.qs.getQP(key)?.length) return true
		}
		return false
	}

	async clearAllFacets(): Promise<void> {
		this.qs.clearAllFacets()

		await this.qs.queryParamsObjectToUrl();
	}

	doFacetClick(facetGroup: string) { // "designation"
		this.facetChoiceEvent.emit(facetGroup);
	}

}

