/*
	MechanicsService is the lowest level service there is. Because it is injected in every other component and service, it must not import anything else than Angular core stuf (this would create a dependency loop).
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { DBInfoSolrResponse, DBNews, SolrError, SuggestQueryParams, SuggestSolrResponse } from '../interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { environment } from '../../environments/environment'
import packagejson from '../../../package.json';

import { IDXDocument } from 'src/app/interfaces';
import { WOption } from '@wipo/w-angular/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { resizeImage } from '../utils';
import { map, take } from 'rxjs';

const rename_special = {
	'by.brandName': 'inids.name',
}

const localesMapping = { // for Intl.DateTimeFormat and language switching
	ar: "ar-LB",
	de: "de-DE",
	en: "en-US",
	es: "es-ES",
	fr: "fr-FR",
//	hi: "hi-IN",
	id: "id-ID",
	ja: "ja-JP",
	ko: "ko-KR",
	pt: "pt-PT",
	ro: "ro-RO",
	ru: "ru-RU",
	zh: "zh-CN",
}

@Injectable({
	providedIn: 'root'
})
export class MechanicsService {

	// General variables
	public isLoading: boolean = false;
	private isLoadingTimeout: any = null;
	public contextualMenuVisible: boolean = true;
	public environment: any;
	public breadcrumbs : boolean = true
	public appVersion: string = `v${packagejson.version}`;

	public isLocalHost: boolean = environment.env.toLowerCase() === 'localhost';
	public isBeta: boolean = false
	public isAwsProd: boolean = environment.env.toLowerCase() === 'awsprod';
	public isAwsAcc: boolean = environment.env.toLowerCase() === 'awsacc';
	public isAwsDev: boolean = environment.env.toLowerCase() === 'awsdev';

	public endpoint: string = null
	public show_news: boolean = false
	public officeCC: string = null
	public supressFacets: boolean = false
	public searchError: SolrError = null
	private searchErrorTimeout = null

	// Image editor
	// public imageEditMode: boolean = false;
	public editingImageIndex: number = null;
	// public base64: string = "";
	public bases64: string[] = [];
	public bases64resized: string[] = [] // If the dropped images are too big, they are resized to a max of 1024 x 1024px. THis array caches the resized bases64
	// public originalBases64: string[] = []; // Not using it (no button to revert to the original dropped image)
	public currentFileName: string = "";

	// Language
	private availableLangs: string[] = Object.keys(localesMapping);
	public lang: string
	public translations: JSON;

	public dateFormatterHuman; // using the native window.Intl.DateTimeFormat. Init in switchLang(). Use with : const formattedDate:string = dateFormatterHuman.format(date:Date);

	// Same. Use with : const formattedDate:string = dateFormatterISO.format(date:Date);
	public dateFormatterISO = new Intl.DateTimeFormat("en-CA",
		{ // "2022-02-01"
			year: "numeric",
			month: "numeric",
			day: "numeric"
		});

	public numberFormatter; // Same. Use with : const formattedNumber:string = numberFormatter.format(num:Number);

	public graphsRange = {
		// { applicationDate: [from, to] }
		range: {},

		add: function (field: string, start: string, end: string): void {
			this.range[field] = [start, end]
		},

		reset: function (field: string = '*'): void {
			if (field !== '*') delete this.range[field]
			else this.range = {}
		},

		contains: function (field: string): boolean {
			return (this.range[field] || []).length
		}
	}

	async downloadBase64ImageFromUrl(imageUrl) {
		return this.http.get(imageUrl, {
			observe: 'body',
			responseType: 'arraybuffer',
		  })
		  .pipe(
			take(1),
			map((arrayBuffer) =>
			  btoa(
				Array.from(new Uint8Array(arrayBuffer))
				.map((b) => String.fromCharCode(b))
				.join('')
			  )
			),
		  ).toPromise()
		/*
		var res = await this.http.get(imageUrl);
		var blob = await res.blob();
	  
		return new Promise((resolve, reject) => {
		  var reader  = new FileReader();
		  reader.addEventListener("load", function () {
			  resolve(reader.result);
		  }, false);
	  
		  reader.onerror = () => {
			return reject(this);
		  };
		  reader.readAsDataURL(blob);
		})
		  */
	  }

	async getBase64ImageFromUrl(imageUrl:string):Promise<string>{
		let base64 = ''
		try{
			base64 = await this.downloadBase64ImageFromUrl(imageUrl) as string
			if(base64.startsWith("data:binary")){
				base64 = base64.split(',')[1]
			}
			if (!base64.startsWith("data:image")) {
				base64 = `data:image/jpeg;base64,` + base64;
			}
		}
		catch(e){
			return ''
		}
		return base64
	}

	public graphsSelection = {
		// { niceClass: ['1','2','3'], designation: ['AA', 'BB'] }
		selection: {},

		state: function (field: string, value: string | number): boolean {
			value = value + ""
			return (this.selection[field] || []).includes(value)
		},

		count: function (field?: string): number {
			return (this.selection[field] || []).length
		},

		add: function (field: string, value: string | number): void {
			this.selection[field] = (this.selection[field] || [])
			this.selection[field].push(value + '')
		},

		remove: function (field: string, value: string | number): void {
			this.selection[field] = (this.selection[field] || []).filter(v => v !== value + '')
		},

		toggle: function (field: string, value: string | number): void {
			if (this.state(field, value)) this.remove(field, value)
			else this.add(field, value)
		},

		reset: function (field: string = '*'): void {
			if (field !== '*') delete this.selection[field]
			else this.selection = {}
		},

		contains: function (field: string): boolean {
			return this.count(field) > 0
		}
	}

	public tooltips = {
		addedToReports: false
	}


	// Navigation between pages same search

	public cachedSt13s: any[] = []

	public viewedSt13sCache: any[] = []

	public statuses: WOption[] = [];
	public news: DBNews[] = [];

	public coverageDataCache: any;

	public activeBrickId: string | null = null; // Used to raise the z-index of the clicked brick in advancedSearch, otherwise Primeng's calendar is below other bricks

	constructor(public ts: TranslateService,
		private http:HttpClient,
		public activatedRoute: ActivatedRoute,
		public router: Router) {

		const l: string = `MS constructor - `

		/*
			if (this.isAwsProd) {
				window.console.log = function () { }
				window.console.warn = function () { }
			}
		*/

		environment.env = (environment.env || "").toLowerCase();

		this.environment = environment;

		this.environment.appLangs = this.availableLangs
			.map((lang: string) => ({
				code: lang,
				link: this.environment.appUrl + `/${lang}`
			}));

		this.isBeta = (localStorage.getItem("gbd.beta") || '') != ''
 
		this.detectEndpoint()

		this.switchLang() // Initializing language immediately, so it's defined.

		this.bases64 = []
		this.loadImageFromSessionStorage()
		// console.log(`${l}bases64 from sessionStorage = `, this.bases64);

		// Extracting the full translations object from Angular translate service :) Cool
		this.ts.onLangChange.subscribe((event: TranslationChangeEvent) => {
			try {
				let tmp = window.location.pathname.split('/')
				let office = tmp[2].replace('IPO-', '')
				if (['WHO', 'LISBON', 'SIXTER'].includes(office)) {
					for (let key in rename_special) {
						let split = key.split('.');
						let needle = event.translations
						split.forEach(key => {
							if (needle) {
								needle = needle[key];
							}
						})
						split = rename_special[key].split('.');
						let new_name = event.translations
						split.forEach(key => {
							if (new_name) {
								new_name = new_name[key];
							}
						})
						this._rename_for_special_collection(event.translations, needle, new_name)
						this._rename_for_special_collection(event.translations, needle.toLowerCase(), new_name.toLowerCase())

					}
				}
			}
			catch (err) {

			}

			// console.log(`${l}Angular TranslationChangeEvent = `, event);
			this.translations = event.translations
			// console.info(`${l}Set ms.translations = `, this.translations);

		});

	}

	addToViewedCache(st13:string){
		if(!this.viewedSt13sCache.includes(st13))
			this.viewedSt13sCache.push(st13)
	}
	clearViewedCache(){
		this.viewedSt13sCache=[]
	}

	addToCache(st13:string){
		if(!this.isInCache(st13))
			this.cachedSt13s.push(st13)
	}

	isInCache(st13:string){
		return this.cachedSt13s.includes(st13)
	}

	removeFromCache(st13:string){
		if(this.isInCache(st13)){
			let pos = this.cachedSt13s.indexOf(st13)
			this.cachedSt13s.splice(pos,1)
		}
	}

	clearCache(){
		this.cachedSt13s=[]
	}


	_rename_for_special_collection(root: any, needle: string, replace: string) {
		for (let key in root) {
			if (typeof root[key] == 'string') {
				if (root[key].includes(needle)) {
					root[key] = root[key].replace(needle, replace)
				}
			}
			else {
				this._rename_for_special_collection(root[key], needle, replace)
			}
		}
	}

	initDateFormatter(locale?: string): void { // 'en-US'

		const l = `ms.initDateFormatter - `

		// console.log(`${l}Passed locale = `, locale)

		locale = locale || new Intl.NumberFormat().resolvedOptions().locale; // 'en-US'

		// console.log(`${l}Using locale = `, locale)

		this.dateFormatterHuman = new Intl.DateTimeFormat(locale, // If this was in utils.ts, the locale won't update after init. For the locale to be dynamic, this needs to be in a service
			{ // "November 27, 2019"
				timeZone: "Europe/London", // 2023-02-15 problems with Seattle users who see dates the day before, attempting to force Europe timezone
				year: "numeric",
				month: "long",
				day: "numeric"
			});
	}

	initNumberFormatter(locale?: string): void {

		locale = locale || new Intl.NumberFormat().resolvedOptions().locale;

		this.numberFormatter = new Intl.NumberFormat(locale); // Default options are good for decimal formatting. 123456 --> '123,456' or '123 456' (returns a string)
	}

	get isMobileView(): boolean {
		return window.innerWidth <= 800
	}
	
	// highlighting corresponding to the result if any
	
	computeIDXDocumentCustomKeys(idxDocument: IDXDocument, highlighting: any = {}): IDXDocument {

		// I put this utility in MechanicsService because it is used in PageResults and in PageReports, so it avoids duplicating the code. I can't put it in utils.ts because it needs MechaniceService.

		const l: string = `MS computeIDXDocumentCustomKeys() - `

		// console.log(`${l}idxDocument = `, idxDocument)

		if (!idxDocument) return;


		idxDocument.human = {};

		// translation of office and its country name
		idxDocument.human['office'] = this.translate(`office.${[idxDocument['office']]}`)
			+ ' (' + this.translate(`designation.${idxDocument['office']}`) + ')';

		// translation of multi-designation
		idxDocument.human['designation'] = idxDocument.office === 'EM'
			? this.translate(`designation.EM`) // only show European Union
			: (idxDocument['designation'] || []).map(cc => this.translate(`designation.${cc}`)).join(", ")
		idxDocument.human['designation_label'] = (idxDocument['office'] === 'WO' || idxDocument['office'] === 'WHO')
			? 'designation' // Intenational
			: idxDocument.office === 'EM' ? 'designation_regional' //Regional
			: 'designation_national' //national

		// concatenate status and its corresponding date
		let statusText = this.translate(`status.${idxDocument['status']}`) // "Registered"

		idxDocument.human['status'] = statusText;

		let statusDateHuman: string = '' // "February 1st, 2022"
		let statusDateIso: string = '' // "February 1st, 2022"

		switch (idxDocument['status']) {
			case 'Pending':
				statusDateHuman = this.dateToHuman(idxDocument['applicationDate'])
				statusDateIso = this.dateToIso(idxDocument['applicationDate'])
				break
			case 'Registered':
				statusDateHuman = this.dateToHuman(idxDocument['registrationDate'])
				statusDateIso = this.dateToIso(idxDocument['registrationDate'])
				break
			case 'Ended':
				statusDateHuman = this.dateToHuman(idxDocument['terminationDate'])
				statusDateIso = this.dateToIso(idxDocument['terminationDate'])
				break
			case 'Expired':
				statusDateHuman = this.dateToHuman(idxDocument['expiryDate'])
				statusDateIso = this.dateToIso(idxDocument['expiryDate'])
				break
			case 'Unknown':
				statusDateHuman = ''
				statusDateIso = ''
				break
		}

		if (idxDocument.office == 'WO' && idxDocument.status == 'Registered') {
			idxDocument.status = 'RegisteredMadrid'
			statusText = this.translate(`status.${idxDocument['status']}`) // "Registered"
			// console.log(idxDocument)
		}

		if (idxDocument.office == 'WO' && idxDocument.status == 'Pending') {
			idxDocument.logo = []
		}

		// "Registered (February 1st, 2022)"
		idxDocument.human['statusDate'] = `${statusText} ${statusDateHuman ? '(' + statusDateHuman + ')' : ''}`

		// "2022-02-01 (for easy sorting in Excel columns) :
		idxDocument.human['date'] = `${statusDateIso}`
		idxDocument.human['expiryDate'] = this.dateToIso(idxDocument['expiryDate'])

		// brand Name : pick up the highlited one or the first if any
		idxDocument.human['brandName'] = (highlighting['brandName'] || []).length
			? highlighting['brandName'][0]
			: (idxDocument.brandName || []).length
				? idxDocument.brandName[0]
				: ''

		// number : according to status and fall back to applicationNumber
		let num: string = ''
		switch (idxDocument['status']) {
			case 'Pending':
			case 'Ended':
				num = idxDocument.applicationNumber
				break
			case 'Unknown':
			case 'Registered':
			case 'Expired':
				num = idxDocument.registrationNumber || idxDocument.applicationNumber
				break
		}
		idxDocument.human['number'] = num

		// applicant : get the first one + add the country if present + add an indication if more exist
		let applicant: string = ''
		applicant = (highlighting['applicant'] || []).length
			? highlighting['applicant'][0]
			: (idxDocument.applicant || []).length
				? idxDocument.applicant[0]
				: ''

		// might happen that the highlight does not correspond to the first applicant
		// but here we get the country code from the first applicant
		// well ...
		if (applicant !== '' && (idxDocument.applicantCountryCode || []).length) {
			applicant += ' (' + this.translate(`designation.${idxDocument.applicantCountryCode[0]}`) + ')'
		}

		// the (+1) looks ugly (remove)
		// more than one applicant exists
		// if ((result.applicant || []).length > 1) {
		//     applicant += ` (+${result.applicant.length - 1})`
		// }
		idxDocument.human['applicant'] = (applicant || "").replace(/,+/g, ",") 

		idxDocument.human['ipr'] = (idxDocument['office'] === 'WO' || idxDocument['office'] === 'WHO'
			? this.translate('general_words.international')
			: idxDocument['office'] === 'EM'
				? this.translate('general_words.regional')
				: this.translate('general_words.national')
		)
			+ ' '
			+ this.translate(`type.${idxDocument['type']}`)
			+ ' '
			+ (idxDocument['status'] === 'Registered' || idxDocument['status'] === 'Expired'
				? this.translate('general_words.registration')
				: idxDocument['status'] === 'Ended' || idxDocument['status'] === 'Pending'
					? this.translate('general_words.application')
					: '')



		if (idxDocument.logo) {
			idxDocument['logoSmall'] = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
			idxDocument['logoBig'] = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
			for(let logo of idxDocument.logo){
				if(!!logo && logo.length){
					this.getBase64ImageFromUrl(this.imageUrl(idxDocument)).then(res => { idxDocument['logoSmall'] = [res]})
					this.getBase64ImageFromUrl(this.imageUrlBig(idxDocument)).then(res => { 
						if (res != '')
							idxDocument['logoBig'] = [res]
						else
							this.getBase64ImageFromUrl(this.imageUrl(idxDocument)).then(res => { idxDocument['logoBig'] = [res]})
					})
				}
			}
		}
	
		


		// Adding a dummy logo
		if (this.isLocalHost) {

			const hasLogo = idxDocument.logo && idxDocument.logo[0] && idxDocument.logo[0].length;

			if (hasLogo) {

				const width = 200 + Math.floor(Math.random() * 400);
				const height = 200 + Math.floor(Math.random() * 200);

				const isDarkBackground = Math.random() > 0.5;

				let bgColor, textColor;

				if (isDarkBackground) {
					bgColor = `bbb`;
					textColor = `fff`;
				} else {
					bgColor = `fff`;
					textColor = `bbb`;
				}

				const brandName = (idxDocument.human.brandName || (width + " + " + height)).replace(/(<([^>]+)>)/ig, ''); // REmoving HTML like emphasis and highlights <em>

				// idxDocument.dummyLogo = `https://picsum.photos/${width}/${height}`; // random photos

				idxDocument.dummyLogo = `https://dummyimage.com/${width}x${height}.jpeg/${bgColor}/${textColor}&text=${brandName.substring(0, 12)}`// Black image with dimensions
			}
		}


		return idxDocument
	}


	detectEndpoint() {

		const l: string = `MS detectEndpoint() - `

		// For some reason, Angular's ActivatedRoute is empty at startup (??) so I'm simply using the good old window.location

		// console.log(`${l}ActivatedRoute.url subscription : trying to detect endpoint from window.location.pathname='${window.location.pathname}'`);

		if (window.location.pathname === "/") {
			// console.log(`${l}'this.endpoint' is "/", the page is probably still loading. Skipping for now`);
			return []
		}

		const errMsg: string = `${l}Could not work out the endpoint from URL : '${window.location.pathname}'. Expecting '/:lang/:endpoint'`;

		try {
			this.endpoint = window.location.pathname.split("/")[2]
			// console.log(`${l}Found endpoint : '${this.endpoint}'`);
		} catch (err) {
			console.error(errMsg);
		}

		if (!this.endpoint) {
			console.error(errMsg);
		}

		// console.log(`${l}Found endpoint='${this.endpoint}'`)
	}



	setOffice(office: string): void {

		const l = `ms.setOffice() - `

		// console.log(`${l}office='${office}'`)


		let officeCode = (office || '').replace(/^IPO-/, '').toUpperCase()

		// verify that the office is included by passing to translation

		if (this.translate('office.' + officeCode) !== 'office.' + officeCode) {
			this.officeCC = officeCode
			return
		}
		//if(officeCode == 'BETA'){
		//	this.isBeta = true
		//	return
		//}
		this.officeCC = null
	}

	async loadImageFromSessionStorage() {

		const l = `loadImageFromSessionStorage() - `

		const imagesString = sessionStorage.getItem("gbd.ms.bases64");
		if (!imagesString || imagesString === 'null') {
			return;
		}

		try {
			this.bases64 = JSON.parse(imagesString)
			this.bases64resized = [await resizeImage(this.bases64[0], 1024)]

		} catch (err) {
			// console.log(`%c${l} Found something in sessionStorage("gbd.ms.bases64"), but could not parse it = '${imagesString}'`, "color:lightblue")
			sessionStorage.removeItem("gbd.ms.bases64")
		}
	}

	get officeName(): string {
		if (this.officeCC) {
			return `${this.translate('office.' + this.officeCC)} (${this.translate('designation.' + this.officeCC)})`
		}

		return null
	}

	makeRoute({ path = this.endpoint, subpath = '', includeOffice = true, office, caller }: { path?: string, subpath?: string, includeOffice?: boolean, office?: string, caller?: string }): string {

		const l = `ms.makeroute() - `

		// console.log(`${l}caller='${caller}'`)

		let parts: string[] = ['', this.lang]

		//if(this.isBeta){
		//	parts.push('IPO-BETA')
		//}

		if (office || (includeOffice && this.officeCC)) {
			parts.push('IPO-' + (office || this.officeCC))
		}
	

		parts.push(path)

		if (subpath) {
			parts.push(subpath)
		}

		const route = parts.join('/');

		// console.log(`${l}route = '${route}'`)

		return route
	}

	setEndpoint(endpoint: string): void {
		this.endpoint = endpoint
		this.show_news = false
	}

	unsetEndpoint(): void {
		this.endpoint = null
	}

	setSearchError(err: HttpErrorResponse, timeout = 10000): void {

		const l = `ms.setSearchError`

		// console.log(`${l}got error = `, err)

		try {
			this.searchError = JSON.parse(err.error); // err.error = "{\"status\":403,\"message\":\"max_results_reached\"}"
		} catch (err) {
			this.searchError = err
			this.searchError.message = 'default'
		}
		console.log(this.searchError)
		clearTimeout(this.searchErrorTimeout);

		this.searchErrorTimeout = setTimeout(() => {
			this.unsetSearchError()
		}, timeout);
	}

	unsetSearchError(): void {
		this.searchError = null
	}


	setLoading(state: boolean = true, caller?: string) {

		const l: string = `MS setLoading() - caller = '${caller}' - `

		/*
			Manages the "processing" UI blocker. Displays it only after 1 second so it's not too intrusive
		*/

		// console.log(`${l}state='${state}' this.isLoadingTimeout='${this.isLoadingTimeout}'`);

		if (state && this.isLoadingTimeout) {
			// Some function asked to display the "is processing" blocker, but there's already a timeout in the process (like, another function asked the same thing 0.5s earlier) so I'm just ignoring this request.
			// console.log(`${l}UI blocker is already scheduled to appear. Ignoring this request.`);
			return
		}

		if (state) {

			const delay: number = 800
			// console.log(`${l}Scheduling UI blocker in ${delay}ms...`);

			this.isLoadingTimeout = setTimeout(() => {
				// console.log(`${l}Displaying UI blocker.`)
				this.isLoading = true
			}, delay)

		} else {

			// console.log(`${l}Clearing UI blocker.`);
			clearTimeout(this.isLoadingTimeout);
			this.isLoadingTimeout = null
			this.isLoading = false;
		}

	}

	switchLang(lang?: string) {

		const l: string = `ms.switchLang() - `

		let routeLang

		try {
			routeLang = window.location.pathname.split("/")[1] // Apparently, activatedRoute.snapshot and stuff aren't yet accessible (a service constructor is quite low-level) so I'm using window.location :P
			routeLang = routeLang.toLowerCase();
			// console.log(`${l}Found lang from URL : ${routeLang}`);
		} catch (err) {
			console.warn(`${l}Could not parse language from URL`);
		}

		lang = lang || routeLang

		if (!lang) {
			// console.log(`${l}Using default language 'en'`);
			lang = 'en';
		} else if (!this.availableLangs.includes(lang)) {
			console.warn(`Language not supported '${lang}'! Falling back to 'en'`)
			lang = "en";
			this.router.navigate(["en", "quicksearch"])
		}

		// console.log(`${l}using lang = ` + lang);

		this.lang = lang;

		this.initDateFormatter(localesMapping[lang]); // Can be undefined. Will defaut to the user's detected locale
		this.initNumberFormatter(localesMapping[lang]);

		// Need to recompute all custom keys because DatesHuman :/
		// To do that, I simply subscribe to the TranslateService.onLangChange Observable in SearchService :) Cool

		// Below : if the chosen lang isn't available, TranslationService will automatically fetch the corresponding i18n/JSON language.
		// To execute last, because this emits an "TranslationChangeEvent" that triggers the onTranslationChange subscription in the constructor, and refresh the this.translations JSON. It also triggers every other subscriptions in services and components, to recompute custom keys. We need the dateFormatter to be initialized before
		this.ts.use(lang);
	}

	imageUrlBig(result: IDXDocument): string {
		return this.imageUrl(result).replace('-th.jpg', '-hi.png')
	}

	imageUrl(result: IDXDocument): string {

		const l: string = `pageResults imageUrl() - `

		/*
			IN :

			collection : "brands"
			st13 : TN50200900E002444
			name : "B1D6715E"


			OUT :

			"http://localhost:9984?thumb=/brands/tntm/TN50200900E002444/B1D6715E-th.jpg"
		*/



		// 	// console.log(`${l}249 - collection='${collection}', st13='${st13}', name='${name}'`)

		if (this.isLocalHost && localStorage.getItem("showDummyImages") === "true") {

			return result.dummyLogo || "";
		}

		const logoName: string = result.logo && result.logo[0];

		if (!logoName) {
			return "";
		}

		const collection: string = result.collection,
			st13: string = result.st13;

		let imagePath = `/brands/${collection}/${st13}/${logoName}-th.jpg`

		let output = environment.imgBaseUrl + imagePath

		// console.log(`${l}Output image URL = `, output);

		return output
	}


	dateToHuman(dateString: string): string {

		const l = `ms.dateToHuman() - `

		// console.log(`\n\n${l}formatting : ${dateString}`)

		if (!dateString) return ''

		// Dates can be "2010-03-02T23:59:59Z" or "1608940799000"

		try {

			let dateShort: string

			if (/^\d{13}$/.test(dateString)) { // "1608940799000" 
				dateShort = new Date(+dateString).toISOString() // '2020-12-25T23:59:59.000Z'
			}

			/*
				Problem : 2012-01-02T23:59:59Z is converted to "January 3" despite the date being January 2.
				It's also converted the day before in other timezones.
				
				I'm removing the T23:59:59Z part. Without any hour nor timezone indication, the day should never be wrong
			*/

			dateShort = dateString.split("T")[0];

			// console.log(`${l}dateShort='${dateShort}'`)

			const newDate: Date = new Date(dateShort)

			// console.log(`${l}newDate.toISOstring() = `, newDate.toISOString())

			const toReturn = this.dateFormatterHuman.format(newDate);

			// console.log(`${l}formatted = `, toReturn)

			return toReturn

		} catch (err) {

			// console.log(`${l}Caught error formatting date '${dateString}' : `, err.message || err)
			return ""
		}
	}

	dateToIso(dateString: string): string {
		if (!dateString) return ''

		// Dates can be "2010-03-02T23:59:59Z" or "1608940799000"

		let date: Date;

		if (/\d{13}/.test(dateString)) {
			date = new Date(+dateString); // "1608940799000" --> "2020-12-25T23:59:59.000Z"
		} else {
			date = new Date(dateString)
		}

		return this.dateFormatterISO.format(date);
	}

	translate(word: string): string {

		const l: string = `ms.translate() - `
		// console.log(`${l}translating ${word}`);

		/*
			Normally, in templates, the | translate pipe is used.
			But from Typescript code, I can just use the JSON language file (which is extracted from the TranslateService in the constructor of MS).
		*/

		if (!this.translations) {
			// console.log(`${l}Translations object hasn't loaded yet [${word}] - this = `, this);
			// It's async, the translations object hasn't loaded yet
			return "⧗";
		}

		// multilevel support for translation json: level1.level2.level3.level...
		let translation: any = this.translations,
			split = word.split('.');

		try {
			split.forEach(key => { // key = 'status' then 'Expired'; 'page_visu' then 'graphing'; etc. It allows to drill down
				if (translation) {
					translation = translation[key]; // this drills down in the i18n JSON file, clever
				}
			})
		} catch (err) {
			// console.log(l, err)
		}

		/*
			If the translation cannot be found, we return split[0].

			In the case of "applicant.hyundai motor company" it will return "hyundai motor company"
		*/

		// return translation even if it is set to ""
		if (translation !== undefined) {
			// console.log(`${l}Returning '${word}' translated in '${this.lang}' language = ${translation}`)	
			return translation
		}

		// console.log(`${l}Could not translate '${word}'`)
		return word
	}

	async waitForTranslations(): Promise<string> {

		const l = `MS waitforTranslations()`

		// Utility that makes sure translation object is loaded and you can use ms.translate()

		while (!this.translations) {
			// console.log(`${l}Translations not yet ready...`)
			await new Promise(r => setTimeout(r, 100))
		}

		return "ok"
	}
}
