import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WOption } from '@wipo/w-angular/shared';
import { asStructure2bricks, bricks2AsStructure, canSearch } from '../../utils';
import { MechanicsService } from '../../_services/mechanics.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { SearchService } from '../../_services/search.service';

@Component({
	selector: 'page-goods-services',
	templateUrl: './page-goods-services.component.html',
	styleUrls: ['./page-goods-services.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageGoodsServicesComponent implements OnInit {

	public strategies: WOption[] = []

	public bricks;

	constructor(private ar: ActivatedRoute,
		public ss: SearchService,
		public ms: MechanicsService,
		public qs: QueryParamsService) {

		this.ms.setEndpoint("goodsservices")
		this.bricks = this.defaultBricks;
	}

	ngOnInit(): void {

		const l = `pageGoodsServices ngOnInit() - `

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		// Mona : if coming back to this page from results page, remove facets --> Jer : But how do you know you're coming back from the search page and not just reloading the page
		this.qs.keepOnlySearchParams();
		this.buildStuff()
	}

	get defaultBricks() {
		return {
			goodsServices: "",
			goodsServicesStrategy: "", // "" or "Terms", if we check the "exact match" checkbox
			office: [],

			// Keys used in the UI, which will be converted to asStructure, but useless for the backend/Solr. I'm keeping them to be able to edit/reload the page and keep all entered data
			isMatchExact: false,
			isYoungerThan: false,
			maxAgeMonths: "6",
		}
	}

	buildStuff() {

		this.strategies = [
			{ value: "exact", label: "" },
			{ value: "keywords", label: "" }
		].map(obj => {
			obj.label = this.ms.translate(`page_goods_services.${obj.value}`);
			return obj
		})

		this.buildBricksFromQP()
	}

	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {

			// console.log(`${l}parsed asStructure = `, JSON.parse(qpAsStructureString))

			this.bricks = Object.assign(this.bricks, asStructure2bricks(JSON.parse(qpAsStructureString)))
		}
	}

	doReset() {
		const l = `page-goosServices doReset - `
		this.bricks = this.defaultBricks;
	}

	onExactCheckboxClicked($event): void {
		const l = `onExactCheckboxClicked() - `

		const isChecked: boolean = $event.target.checked
		// console.log(`${l}isChecked? ${isChecked}`)
		this.bricks.goodsServicesStrategy = isChecked ? "Terms" : "";
	}


	isChecked(value: string): boolean {

		const statuses: string[] = this.qs.getQP("status");
		return statuses.includes(value)

	}

	get canSearch(): boolean {

		return canSearch(this.bricks.goodsServices)
	}


	async search() {

		const l = `pageGoodsServices search() - `

		if (!this.canSearch) return;

		if (this.bricks.isYoungerThan) {
			/*
			special treatment for "Younger than 6 months" - adding to this.bricks :
			
			{
				"_id": "",
				"key": "regDate",
				"strategy": "Min",
				"value": "2022-11-27"
			},
			*/

			const howLongAgo: Date = new Date();
			howLongAgo.setMonth(howLongAgo.getMonth() - Number(this.bricks.maxAgeMonths));

			this.bricks["regDate"] = howLongAgo.toISOString().substring(0, 10);
			this.bricks["regDateStrategy"] = "Min"
			// delete this.bricks.isYoungerThan; // Not sure if that's a good idea
		} else {
			// Important! So as to not have remnants from a previous checkbox that is now unticked
			delete this.bricks["regDate"]
			delete this.bricks["regDateStrategy"]
		}

		// console.log(`${l}this.bricks before converting to asStructure = `, this.bricks)

		const asStructure = bricks2AsStructure(this.bricks);

		// console.log(`${l}asStructure = `, asStructure)

		this.qs.setQP("asStructure", JSON.stringify(asStructure));
		this.qs.setQP("fg", "_void_")

		const route: string = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}


}

