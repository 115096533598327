import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { WOption } from '@wipo/w-angular/shared';

import { environment } from 'src/environments/environment';
import { SearchSolrResponse, SolrError } from 'src/app/interfaces';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { SearchService } from 'src/app/_services/search.service';
import { decrypt } from 'src/app/utils';


@Component({
	selector: 'page-reports',
	templateUrl: './page-reports.component.html',
	styleUrls: ['./page-reports.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageReportsComponent implements OnInit {


	public optionsDownloadMode: WOption[] = []
	public optionsFormat: WOption[] = []
	public reports: any = {} //IDXDocument[] = []
	public downloadMode: string; // "full"

	public isLoading: Boolean;
	public isExporting: boolean = false; // I switch it to true during export, to avoid the view being rebuilt by ms.loading true/false and destroying the dynamic rendering
	public searchError: SolrError = null

	private urlchangeSubscription: any;

	constructor(
		private ar: ActivatedRoute,
		public rs: ReportsService,
		private ms: MechanicsService,
		private ss: SearchService,
		public qs: QueryParamsService) {

		const l = `page-reports constructor - `

		this.ms.setEndpoint("reports")
		// this.qs.resetQP("reports");
	}

	async ngOnInit(): Promise<void> {

		await this.rs.loadData(); // can't use await in the construtor

		this.buildStuff();

		this.urlchangeSubscription = this.ar.queryParams.subscribe(queryParams => {
			this.qs.urlToQueryParamsObject(queryParams, 'reports');
			// a report was open before
			let reportName = this.qs.getQP('reportName')
			if (reportName) {
				this.rs.reports.st13[reportName].collapsed = false
				this.toggleReport(false, reportName)
			}
		});
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
		this.urlchangeSubscription.unsubscribe();
	}

	buildStuff() {
		// Whatever needs to be rebuilt and translated when the language changes, put it here
		this.buildOptionsDownloadMode()
		this.buildOptionsFormat()
	}

	toggleReport($event, reportName) {
		const l = `pageReports onClicked() - `

		let isOpen = !$event

		// console.log(`${l}isOpen=`, isOpen)

		if (!isOpen) {
			// closed the report => remove the queryParam
			delete this.qs.queryParams['reports']
			return
		}

		// Mona : handle query params manually here as they are not translated in the router (needed for export)
		// could be a future #TODO
		this.qs.queryParams['reports'] = { reportName }

		// not loaded before => hydrate!
		this.ms.setLoading(true, l);

		this.rs.hydrate(reportName).subscribe({
			next: (searchResult: SearchSolrResponse) => {
				this.ms.setLoading(false);

				searchResult = decrypt(searchResult);

				this.setSearchResult(searchResult, reportName);

			},
			error: (err: HttpErrorResponse) => {
				this.ms.setLoading(false)
				this.setSearchError({ status: err.status, message: err.message });
			}
		})

	}

	setSearchResult(searchResult: SearchSolrResponse, reportName: string): void {

		const l = `pageReports.setSearchResult() - `

		// console.log(`${l}searchResult = `, searchResult)

		if (!searchResult.response || !searchResult.response.docs) {
			console.error(`${l}No searchResult.response.docs!`)
			return;
		}

		for (let doc of searchResult.response.docs) {
			doc = this.ms.computeIDXDocumentCustomKeys(doc)
			// put this here so that the reportName is carried to the details method below
			doc['reportName'] = reportName
		}

		this.reports[reportName] = searchResult

		// console.log(`${l}Observing results`)

		this.ss.observeResults(searchResult.response.docs, false) // reset=false because I don't want the watchlist to be reset every time we hydrate a new report (others get blank). So I make it additive
	}


	setSearchError(err: SolrError) {
		this.searchError = err
	}

	details(doc: any, idx: number) {

		const l = `pageReports details() - `

		let st13 = doc.st13
		let reportName = doc.reportName

		// Initializing ss.searchResult with our report (it's a SolrResponse) so we get the corrent "prev/next" buttons
		// const solrResponse: SolrResponse = this.rs.getReportFromSt13(st13);
		// // console.log(`${l}Found solrResponse = `, solrResponse)

		this.ss.searchResult.init(this.reports[reportName]);

		this.ss.searchResult.setCursor(idx)
		// to be changes with next/prev buttons to force page reload
		// we do not change '_' here as it is being used as a query identifier
		// console.log(`${l}setQP 'i' with idx=`, idx)
		this.qs.setQP('i', idx)

		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `brand/${st13}`, caller: l })

		this.qs.queryParamsObjectToUrl(route)
	}

	// ------------------
	// ACTIONS on REPORTS
	// ------------------
	isSomeResultSelected(reportName: string): boolean {
		return this.reports[reportName]?.response.docs.some(result => result && result.selected)
	}
	removeFromReport(reportName: string) {
		// remove from display
		this.reports[reportName].response.docs = this.reports[reportName].response.docs.filter(doc => !doc.selected);

		// remove from storage
		let st13s = this.reports[reportName].response.docs.map(d => d.st13);
		this.rs.updateReport(reportName, st13s)
	}
	toggleAll(reportName: string) {
		let setTo: boolean = !this.isAllSelected(reportName)
		this.reports[reportName]?.response.docs.forEach(doc => doc.selected = setTo)
	}
	isAllSelected(reportName: string): boolean {
		return this.reports[reportName]?.response.docs?.every(doc => doc.selected);
	}

	doExport(reportName: string, format: string) {

		this.ms.setLoading(true);
		this.isExporting = true;

		window.addEventListener('message', this._menuaction_export_done.bind(this), false);

		const iframe: any = document.getElementById("resultsExport");
		iframe.setAttribute('src', `/${this.ms.lang}/reports/export?reportName=${encodeURIComponent(reportName)}&format=${format}`);
	}

	_menuaction_export_done(event): void {
		if (event.origin === environment.appUrl && event.data === 'export_done') {
			this.ms.setLoading(false);
			this.isExporting = false;
			window.removeEventListener('message', this._menuaction_export_done)
		}
	}

	buildOptionsDownloadMode() {
		this.optionsDownloadMode = [
			{ value: "list", label: this.ms.translate("page_reports.simple_list") },
			{ value: "full", label: this.ms.translate("page_reports.full_reports") },
		]
	}

	buildOptionsFormat() {
		this.optionsFormat = [
			{ value: "pdf", label: "PDF" },
			{ value: "excel", label: "Excel" },
		]
	}

}
