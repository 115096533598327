import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ExportComponent } from './export/export.component';

@Component({
	selector: 'page-export',
	templateUrl: './page-export.component.html',
	styleUrls: ['./page-export.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageExportComponent implements OnInit {

	contentComponent: any;

	ngOnInit() {
		this.contentComponent = ExportComponent;
	}
}
