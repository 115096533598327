import { ViewEncapsulation,  Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { RecentSearchesService } from 'src/app/_services/recentSearches.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { SearchService } from 'src/app/_services/search.service';
import { SearchFacets, IDXDocument } from 'src/app/interfaces';

const queryString = require('query-string');


@Component({
	selector: 'results',
	templateUrl: './results.component.html',
	styleUrls: ['./results.component.css'],
	changeDetection: ChangeDetectionStrategy.Default, // OnPush --> blank page...
	encapsulation: ViewEncapsulation.None
})

export class ResultsComponent implements OnInit {

	public solrFacets: SearchFacets = null
	public highlighting: any = {}
	public isMock: boolean

	// Reporting stuff
	public newReportName: string = "";
	public checkedReports: any = {}; // { "fruits": true, "Test 03": true, "vegetables": false }

	constructor(public ss: SearchService,
		private rss: RecentSearchesService,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public qs: QueryParamsService,
		public rs: ReportsService) {
	}

	ngOnInit() {
		const l = `pageResult ngOnInit - `

		if (!this.rs.reportsNames.length) this.rs.loadData()

		this.ss.searchResult.numFound = -1 // To display "Loading..." in comp-results-info
	}

	isResultVisible(_id: string) {
		return this.ss.resultsVisibility[_id]
	}



	setSearchResult(searchResult:any = {}) {

		const l = `resultsComponent setSearchResult() - `
		
		// console.log(`${l}setting searchResult = `,searchResult)

		// set the result in the search service cache
		// will also update the results-info
		let cid: string = this.qs.getQP('_');
		
		this.ss.searchResult.init(searchResult, cid);

		this.isMock = searchResult.isMock
		this.highlighting = searchResult.highlighting || {}
		this.solrFacets = searchResult.facets
		for (let doc of this.ss.searchResult.docs) {
			this.ms.computeIDXDocumentCustomKeys(doc, this.highlighting[doc.st13])
		}
		this.rss.addRecentSearch(this.qs.getQP("*"))
		document.getElementsByClassName('mainGrid')[0].scrollTo({ top: 0, behavior: 'smooth' });

		this.ss.observeResults();
	}

	setSearchError(err: HttpErrorResponse) {

		const l = `resultsComponent setSearchError() - `

		switch (err.status) {
			case 403:
				// stay in the search result page and display the same results as before

				const resutsToSet = this.ss.searchResult.currentResult
				// console.log(`${l}resutsToSet = `, resutsToSet)
				this.setSearchResult(resutsToSet)
				this.ms.setSearchError(err)
				break
			default:
				// back to the search form
				this.qs.queryParamsObjectToUrl(this.ms.makeRoute({ path: this.ms.endpoint, caller: l })) // go back to search form
				this.ms.setSearchError(err)  // error mesage will disappear on search form OnDestroy
				break
		}

	}



	// -----------------------------------
	// search results menu handlers
	// -----------------------------------
	// { name : name_of_the_menu } --> { name : "sort" }
	onMenuChoice($event): void {

		const l = `pageResults onMenuChoice() - `

		// console.log(`${l}$event = `, $event)

		// $event.name can be "layout", "sort", "rows"
		this[`_menuchoice_${$event.name}`]($event.value)

		// store the preferences made by the menu
		this.ps.storePreferences()
	}

	// { name : name_of_the_menu, value: value_of_the_choice_if_any } --> { name : "watch" }
	onMenuAction($event): void {

		const l = `pageResults onMenuAction() - `

		// $event.name can be "export", "watch", "save"
		this[`_menuaction_${$event.name}`]($event.value)
	}

	_menuaction_export(format: string, notified: boolean = false): void {

		const l = `resultsComponent _menuaction_export() - `

		this.ms.setLoading(true);

		// listen to the message from iframe
		window.addEventListener('message', this._menuaction_export_done.bind(this), false);

		let payload = this.qs.queryParams[this.ms.endpoint]

		payload = Object.keys(payload)
			.filter(k => !!payload[k] && k !== '_' && k !== 'i')
			.reduce((a, k) => ({ ...a, [k]: payload[k] }), {});

		payload['fg'] = '__void__'; // no need for facets when we export
		payload["images"] = true; // requesting full base64 in response, instead of just logo code
		payload["format"] = format;
		const stringified = JSON.stringify(payload);

		localStorage.setItem(
			`gbd.download_report`,
			stringified
		);
		const route = this.ms.makeRoute({path:this.ms.endpoint, subpath:'export', caller : l})

		const iframe: any = document.getElementById("resultsExport");
		const url = `${route}`;

		/*
			The iFrame visits /en/quicksearch/export?by=brandName&fg=__void__&rows=15&sort=applicationDate%20desc&v=cherry&format=PDF

			which triggers (all inside the iFrame) HOCcomponent.ngOnInit(), then ss.search(), then ExportComponent.export() 

		*/

		// console.log(`${l}Setting iframe's URL : '${url}'`)

		iframe.setAttribute('src', url);
	}

	_menuaction_export_done(event): void {

		const l=`_menuaction_export_done`

		// console.log(`${l}`)

		if (event.origin === environment.appUrl && event.data === 'export_done') {
			this.ms.setLoading(false);
			window.removeEventListener('message', this._menuaction_export_done)
		}
	}

	_menuaction_watch(): void { alert('work in progress') }
	_menuaction_save(): void { alert('work in progress') }

	_menuchoice_layout(layout: string): void {
		const l: string = `pageResult _menuchoice_layout() - `
		this.ps.setPref("layout", layout)

		this.ss.observeResults()
	}

	_menuchoice_sort(order: string): void {
		const l: string = `pageResult _menuchoice_sort() - `

		// when sorting, start from the beginning
		this.qs.rmQP("start")
		this.qs.setQP("sort", order)
		this.ms.supressFacets = true // a switch that will be read and reset in search
		this.qs.queryParamsObjectToUrl()
	}

	_menuchoice_rows(rows: number): void {
		const l: string = `pageResult _menuchoice_rows() - `

		// when number of rows change, start from the beginning
		this.qs.rmQP("start")
		this.qs.setQP("rows", rows)
		this.ms.supressFacets = true // a switch that will be read and reset in search
		this.qs.queryParamsObjectToUrl()
	}


	// -----------------------------------
	// search results handlers
	// -----------------------------------


	get windowWidth(): number {
		return window.innerWidth
	}

	// ------------------
	// TABLE INTERACTIONS
	// ------------------
	get isAllSelected(): boolean {
		return this.ss.searchResult.docs.every(result => result && result.selected)
	}

	get countSelectedResults(): number {
		return this.ms.cachedSt13s.length
	}

	deselectAll() {
		this.ss.searchResult.docs.forEach(result => result.selected = false)
	}

	async onPagination($event): Promise<void> {

		const l: string = `pageResult onPagination() - `
		// console.log(`${l}$event = `, $event);

		this.ms.supressFacets = true // a switch that will be read and reset in search
		this.qs.setQP("start", $event.first)
		await this.qs.queryParamsObjectToUrl()
	}

	/*
		╦═╗┌─┐┌─┐┌─┐┬─┐┌┬┐┬┌┐┌┌─┐
		╠╦╝├┤ ├─┘│ │├┬┘ │ │││││ ┬
		╩╚═└─┘┴  └─┘┴└─ ┴ ┴┘└┘└─┘
	*/

	get checkedReportsKeys() {
		return Object.keys(this.checkedReports).filter(key => this.checkedReports[key])
	}

	

	async addToReports() {

		const l = `PageResults addToReports() - `
		// Add the last selections to the cache

		// console.log(`${l}this.checkedReportsKeys = `, this.checkedReportsKeys);

		for (let reportName of this.checkedReportsKeys) {// ["fruits", "Test 03", "vegetables"]
			if (reportName === "$$new") reportName = "" + this.newReportName;
			await this.rs.setReport(reportName, this.ms.cachedSt13s);
		}

		this.checkedReports = []
		this.newReportName = ""

		this.deselectAll();
		this.ms.clearCache();
		this.ms.tooltips.addedToReports = true;
		setTimeout(() => {
			this.ms.tooltips.addedToReports = false;
		}, 3000)
	}
}

