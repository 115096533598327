import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, from, lastValueFrom, Observable, Subject } from 'rxjs';
import { DBInfoSolrResponse } from '../interfaces';
import { MechanicsService } from './mechanics.service';
import { environment } from '../../environments/environment';
import queryString from 'query-string';
import { HttpClient } from '@angular/common/http';
import { WOption } from '@wipo/w-angular/shared';
import { deepClone } from '../utils';

const redirections = {
	// applicant_cc: "designation", // "applicant_cc" will suggest countries (designation)
	refOffice: "office"
}

@Injectable({
	providedIn: 'root'
})
export class OptionsListService {

	public hardcodedLists = {
		// STATIC LISTS - built in buildHardcodedLists()
		designation: [], // List of all countries
		office: [], // List of all IP offices
		status: [],
		niceClass: [], // Nice suggestions are hardcoded, other classification make an HTTP call to the backend	
		applicant_cc : [],
		markFeature: []
	}
	private hardcodedKeys = Object.keys(this.hardcodedLists)

	/* 
		suggestions : empty at start. Is reused for everything with a suggestion list : brandName, viennaClass, niceClass, usDesignClass, etc.
		  w-select-many takes WOption[], but w-input-autocomplete takes string[]...
	*/

	public suggestions: WOption[] = []; // | string[]
	public dbInfoCache: any = {};
	public $suggestTerms = new Subject<string>()
	public isSuggesting: boolean = false; // boolean is not enough. I need to know what the service is currently suggesting, in order to not display the loading gif in every suggestion list on the page. isSuggesting="niceClass"
	public noSuggestions: boolean = false; // This is not the same as suggestions.length===0. It can be 0 at the beginning. But if it's 0 after a search, then this flag turns true.
	public what: string = ""; // storing the type of what we're looking for (niceClass, viennaClass, etc.) When loading, isSuggesting will take this value
	public componentId: number = null // Little hack to avoid opening all the suggestions drawers of all the same components on the page. I'm giving each one an ID


	private markFeature: string[] = [
		"Colour",
		"Combined",
		"Figurative",
		"Hologram",
		"Motion",
		"Multimedia",
		"Olfactory",
		"Other",
		"Pattern",
		"Position",
		"Sound",
		"Stylized characters",
		"Three dimensional",
		"Tracer",
		"Undefined",
		"Word",
	]

	constructor(public ms: MechanicsService,
		public http: HttpClient) {

		this.buildHardcodedLists()

		from(this.$suggestTerms)
			.pipe(
				filter(res => res.length > 1),
				distinctUntilChanged(),
				debounceTime(400)
			)
			.subscribe((params) => {
				// console.log(`$suggestTerms subscription - params = `, params)
				this.callSuggest(params)
			})
	}

	async buildHardcodedLists() {

		const l = `ols.buildHardcodedLists() - `

		/*
			Building the static autocomplete lists (countries, IP offices...)
			Non-static (suggested) lists are done on the fly (viennaClass, usDesignClass)
		*/

		await this.ms.waitForTranslations();

		// BUILDING NICE CLASS OPTIONS LIST
		this.hardcodedLists.niceClass = Object.keys(this.ms.translations["niceClass"])
			.filter(key => key !== "_void_")
			.map(key => ({
				value: key,
				label: key, // For Nice class, we only want "10" in labels and queryParams2Human, not the full blast description
				label2: key + " - " + this.ms.translations["niceClass"][key],// What is displayed in the suggestion list (full blast description)
				info: (key + this.ms.translations["niceClass"][key]).toLowerCase(),
			})
			);
		// console.log(`${l}Built Nice list = `, this.hardcodedLists.niceClass)

		// BUILDING STATUS OPTIONS LIST
		for (let status of ["Registered", "Ended", "Pending", "Expired", "Unknown"]) {

			this.hardcodedLists.status.push({
				value: status,
				label: this.ms.translate(`status.${status}`)
			})

		}

		for (let status of this.markFeature) {

			this.hardcodedLists.markFeature.push({
				value: status,
				label: this.ms.translate(`markFeature.${status}`)
			})

		}

		try {

			let dbinfo: DBInfoSolrResponse = await this.getDbInfo({ caller: l });

			// console.log(`${l}got dbInfo = `, dbinfo)

			// BUILDING DESIGNATION OPTIONS LIST
			this.hardcodedLists.designation = dbinfo.facets.designation.buckets // [{val: 'JP', count: 5777543}]
				.concat([
					{ val: "WO", count: 0 },
					{ val: "EP", count: 0 },
					{ val: "WHO", count: 0 }
				])
				.map((obj: any): WOption => ({
					value: obj.val,
					label: "(" + obj.val + ") " + this.ms.translate("designation." + obj.val),
					info: (obj.val + this.ms.translate("designation." + obj.val)).toLowerCase() // lowercase, for faster search/match
				}))
				.sort((a, b) => a.label.localeCompare(b.label));

			// BUILDING OFFICE OPTIONS LIST
			this.hardcodedLists.office = dbinfo.facets.office.buckets // [{val: 'JP', count: 5777543}]
				/*.concat([
					{ val: "WO", count: 0 },
					{ val: "EP", count: 0 },
					{ val: "WHO", count: 0 }
				])*/
				.map((obj: any): WOption => ({
					value: obj.val,
					label: "(" + obj.val + ") " + this.ms.translate("office." + obj.val),
					info: (obj.val + this.ms.translate("office." + obj.val)).toLowerCase() // lowercase, for faster search/match
				}))
				.sort((a, b) => a.label.localeCompare(b.label));

			// BUILDING APPLICANT CC LIST
			this.hardcodedLists.applicant_cc = dbinfo.facets.applicantCountryCode.buckets // [{val: 'JP', count: 5777543}]
			/*.concat([
				{ val: "WO", count: 0 },
				{ val: "EP", count: 0 },
				{ val: "WHO", count: 0 }
			])*/
			.map((obj: any): WOption => ({
				value: obj.val,
				label: "(" + obj.val + ") " + this.ms.translate("designation." + obj.val),
				info: (obj.val + this.ms.translate("designation." + obj.val)).toLowerCase() // lowercase, for faster search/match
			}))
			.sort((a, b) => a.label.localeCompare(b.label));


		} catch (err) {

			// console.log(`${l}Caught error (couldn't fetch DbInfo): `, err)
			
		}

		console.log(`${l}Have built this.hardcodedLists = `, this.hardcodedLists)
	}

	async getDbInfo(params: any = {}): Promise<DBInfoSolrResponse> {
		const l = `OLS getDbInfo() - `

		console.log(`${l}caller = `, params.caller)

		delete params.caller;

		if (this.ms.officeCC) {
			params.office = params.office || this.ms.officeCC
		} 
		if (this.ms.isBeta){
			params.office = 'beta'
		}

		// remove param keys that have empty lists
		for (let key of Object.keys(params)) {
			if (Array.isArray(params[key])) {
				if (params[key].length == 0) {
					delete params[key]
				}
			}
		}

		const key = JSON.stringify(params)

		if (this.dbInfoCache[key]) {
			// console.log(`${l}returning dbInfoCache['${key}'] = `, this.dbInfoCache[key])
			return this.dbInfoCache[key];
		}

		// See if anything is left
		let isNoParamDBInfo = Object.keys(params).length === 0;

		const route = isNoParamDBInfo
			? `${environment.backendUrl || ""}/dbinfo`
			: `${environment.backendUrl || ""}/dbinfo?${queryString.stringify(params)}`;


		// console.log(`${l}no dbInfoCache['${key}'] found, querying server on '${route}'`)

		let response // :SolrResponse
		try {
			response = await lastValueFrom(this.http.get(route))
		}
		catch (err) {
			// console.log(`${l}Caught error = `, err);
			return
		}

		this.dbInfoCache[key] = response;

		//console.log(`${l}Got dbinfo response for '${key}' = `, response)
		
		return response
	}

	// what: applicant / representative / brandName / viennaClass / usDesignClass / 
	suggest($event: KeyboardEvent, what: string, componentId?: number, options?: string[]): void {

		const l: string = `OLS doSuggest() - `

		this.noSuggestions = false
		this.what = redirections[what] || what;
		what = "" + this.what;
		this.componentId = componentId; // Little hack to avoid opening all the suggestions drawers of all the same components on the page. I'm giving each one an ID

		const word = (<HTMLInputElement>$event.target).value.trim();

		if (what === "undefined"
			||
			["Enter", "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown", "Space"].includes($event.key)
		) {
			return
		}


		// Some lists are hardcoded
		if (this.hardcodedKeys.includes(what)) {
			this.suggestHardcoded(word)
			return
		}

		// Disable calls to brandac (for now)
		switch (what) {
			case 'brandName':
			case 'applicant':
			case 'representative':
			case 'number':
				console.info(`%c${l}Have disabled suggestions for ${what}.`, 'color: lightblue')
				return
		}

		let params = { what, word }

		console.log(`${l}params = `, params)

		// limit search to an office
		if (this.ms.officeCC && !["viennaClass", "usDesignClass"].includes(what)) {
			params['cc'] = this.ms.officeCC
		}

		const queryParams = queryString.stringify(params);

		this.$suggestTerms.next(queryParams) // Subsciption will debounce and apply distinctUntilchanged(), then call callSuggest()
	}

	callSuggest(queryParams: string): void {

		const l = `OLS callSuggest() - `

		// console.log(`${l}queryParams = `, queryParams) // what=usDesignClass&word=05

		const url = `${environment.backendUrl || ""}/suggest?${queryParams}`;

		// console.log(`${l}calling URL : `, url)

		this.suggestions = [];
		this.isSuggesting = true

		const word = new URLSearchParams(queryParams).get("word");

		try {
			this.http.get(url).subscribe(res => this.resToSuggest(res, word))
		} catch (err) {
			// console.log(`${l}Caught error = `, err)
			this.isSuggesting = false
		}
	}

	resToSuggest(res: any, word: string): void {

		const l = `resToSuggest() - `
		this.isSuggesting = false

		// console.log(`${l}res = `, res)

		/*

			Need to transform SolrResponse into WOption[]

			res = {
					"suggest": {
						"description_en": {
							"sun": {
								"numFound": 42,
								"suggestions": [
									{
										"term": "05.07.06 - Seeds, including sunflower and pumpkin seeds",
										"weight": 0,
										"payload": ""
									},
									{
										"term": "01.05 - Sun",
										"weight": 0,
										"payload": ""
									},
									{
										"term": "05.05.05 - Daisies, sunflowers, dandelions",
										"weight": 0,
										"payload": ""
									},
									{
										"term": "01.05.01 - Sun, rising or setting (partially exposed or partially obstructed)",
										"weight": 0,
										"payload": ""
									},
									{
										"term": "01.03.12 - Sun with other figurative elements",
										"weight": 0,
										"payload": ""
									},
		
								]
							}
						}
					}
				}
		
		*/


		try {

			// GRRRR keys are dynamic. Unfolding the response
			let antiloop = 1;

			while (antiloop < 10 && !Array.isArray(res)) {
				// console.log(`${l}loop ${antiloop} - res = `, res);
				res = res["suggest"] || res["suggestions"] || res[Object.keys(res)[0]];
				antiloop++
			}
			// console.log(`${l}unfolded res = `, res)

			/*
			this.suggestions.length = 0;

			for(let obj of res){

				const value:string = obj.term.split(" - ")[0];

				this.suggestions.push({
					label: obj.term,
					value
				})
			}
			*/
			this.suggestions = (res as any[])
				.map(s => {

					const value = s.term.split(" - ")[0];
					const regexg = new RegExp(word, "ig");

					let toReturn = {
						value,
						label: value, // Short value
						label2: s.term, // Full value
						score: 0,
						highlighted: null
					};

					const highlighted = s.term.replace(regexg, (match, index) => {
						// console.log(`'${wOption.label}' match index for '${word}' = ${index}`)
						toReturn.score += 100 - index;
						return `<em>${match}</em>`
					})

					toReturn.highlighted = highlighted

					return toReturn
				})
				.sort((a, b) => a.score > b.score ? -1 : 1)

		} catch (err) {
			console.error(`${l}caught error : `, err)
			// No suggestions
		}

		if (this.suggestions.length === 0) {
			this.noSuggestions = true
		}
	}

	suggestHardcoded(word: string) {

		const l = `ols.suggestHardcoded() - `

		console.log(`${l}Suggesting from hardcoded list '${this.what}' = `, this.hardcodedLists[this.what])

		const regex = new RegExp(word, "i");
		const regexg = new RegExp(word, "ig");

		this.suggestions = deepClone(this.hardcodedLists[this.what] || [])
			.filter(wOption => regex.test(wOption.info || wOption.label))
			.map(wOption => {

				wOption.score = 0

				wOption.highlighted = (wOption.label2 || wOption.label).replace(regexg, (match, index) => {
					// console.log(`'${wOption.label2}' match index for '${word}' = ${index}`)
					wOption.score += 100 - index;
					return `<em>${match}</em>`
				})
				return wOption
			})
			.sort((a, b) => a.score > b.score ? -1 : 1)



		console.log(`${l}this.suggestions = `, this.suggestions)
	}
}
