import { Injectable } from '@angular/core';
import queryString from 'query-string';

import {
	SearchSolrResponse, IDXDocument,
	DocumentsQueryParams,
	SuggestQueryParams, SuggestSolrResponse,
	DBInfoSolrResponse,
	DBNews,
	GBDSolrResponse
} from '../interfaces';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from "../../environments/environment"
import { MechanicsService } from './mechanics.service';
import { lastValueFrom, Observable, of, delay } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { decrypt, deepClone } from '../utils';
import { ActivatedRoute } from '@angular/router';


@Injectable({
	providedIn: 'root'
})
export class SearchService {

	public facetsCache: any = null;

	public searchResult: any = {
		cid: '', // cache id : queryParam['_'] --> But why call it 'cid' and not just '_' ?
		lastUpdated: 0,
		result: {}, // SolrResponse

		// handy pointers
		numFound: 0,
		start: 0,
		end: 0,

		// active document offset in details page
		cursor: 0,

		init(solrResult: SearchSolrResponse = {}, cid?: string) {

			const l = `ss.searchResult.init() - `;

			// console.log(`${l}solrResult = `,solrResult)

			this.cid = "" + cid; // used to cache the searchResult
			this.result = solrResult;

			if (!solrResult.response && !solrResult.facets) {
				// console.log(`SS.init() - no SolrResult.response!`)
				return;
			};

			if (solrResult.facets) {

				// #temporaryThatLasts - hiding odd things that come back from Solr : Nice classes greater than 45, "--" country code, _void_ values, etc.
				// console.log(`${l}solrResult.facets = `, solrResult.facets)

				for (let key of Object.keys(solrResult.facets)) { // key == "applicant", "applicantContryCode", "count" ...
					if (!solrResult.facets[key].buckets) continue;

					solrResult.facets[key].buckets = solrResult.facets[key].buckets.filter(obj => {
						// console.log(`${l}${key} - filtering obj=`,obj)
						if (['_void_', "--", "-"].includes(obj.val)) return false;
						if (key === "niceClass" && obj.val > 45) return false;
						return true
					})
				}

				this.facetsCache = solrResult.facets
			} else {
				this.facetsCache = null
			}

			this.numFound = (solrResult.facets && solrResult.facets.count) || solrResult.response.numFound;

			this.start = (typeof (solrResult.response?.start) === "number") ? solrResult.response.start + 1 : 1;
			this.end = this.start + this.docs.length - 1;

			// needs to be the last thing that gets updated
			// as it triggers SearchResultsInfo ngOnChanges()
			// Jer : No, this code is synchronous anyway
			this.lastUpdated = Date.now();

			// console.log(`${l}this.result = `, this.result)
		},

		get currentResult(): SearchSolrResponse {
			this.lastUpdated = Date.now()
			return this.result
		},
		
		get docs(): IDXDocument[] {
			
			const l = `get ss.searchResult.docs - `
			return (this.result?.response || {}).docs || [];
		},

		reset(): void {
			this.cid = ''
			this.lastUpdated = 0
			this.result = {}

			this.numFound = 0
			this.start = 0
			this.end = 0
			this.cursor = 0
		},

		// when we open details page
		setCursor(idx): void {
			const l = `searchResult.setCursor - `
			// console.log(`${l}idx='${idx}' this.start='${this.start}' this=`, this)
			this.cursor = idx + this.start
		},

		// set cursor to top and return st13
		getFirstRecord(): string {
			this.cursor = this.start
			return this.docs[0].st13
		},
		getNextRecord(): string {
			if (!this.hasNextRecord) return null
			this.cursor++
			return this.docs[this.cursor - this.start].st13
		},
		getPrevRecord(): string {
			if (!this.hasPrevRecord) return null
			this.cursor--
			return this.docs[this.cursor - this.start].st13
		},
		getLastRecord(): string {
			const docs = this.docs
			this.cursor = this.start + docs.length - 1; // Keeps the state
			return docs[docs.length - 1].st13
		},

		// questions about next
		hasNext(): boolean {
			return this.hasNextRecord() || this.hasNextPage()
		},
		hasNextRecord(): boolean {
			return this.docs.length > this.cursor - this.start + 1
		},
		hasNextPage(): boolean {
			return this.cursor < this.numFound
		},

		// questions about previous
		hasPrev(): boolean {
			return this.hasPrevRecord() || this.hasPrevPage()
		},
		hasPrevRecord(): boolean {
			return this.cursor - this.start > 0
		},
		hasPrevPage(): boolean {
			return this.cursor > 1
		}
	}

	/*

		OK DOCUMENTATION! About the observer and results lazy scrolling

		Problem : *ngFor building many results was REALLY heavy and was freezing the app for several seconds
		-------
		
		What I wanted to achieve : build minimal results (just their shells with the flag in the background) and then detect when they're in view, if they are, render their full content.
		------------------------
		
		How I did it :
		-------------

		1) *ngFor builds the view, but only the results shells, without content, so it's super fast

		2) Immediately before switching to tab 2 (results), I set a timeout with no duration, which will resolve after *ngFor has synchronously built the results. This timeout is in a function, observeResults().

		Note 1 : This observe function is called when the results tab is clicked, but also whenever a family is picked, if we happen to already be on the results tab.

		Note 2 : this function used to be in TabResultFamiliesComponent, but I need to call it on socket data received, so it  needs to be shared in ManagerService. Which means the rest (the Observer and resultsVisibility) must also be here

		4) After the *ngFor has finished building all the empty results, I reset the observer, and I register all <li> results in it to watch their visibility.
			Since the Observer takes DOM elements, I have to use document.querySelectorAll on the <li> results. The problem, of course, is that it's outside of Angular's world, so I had to find a way to notify Angular that such or such result is visible or invisible.

		5) In order to do that, I write each result's _id in an _id attribute.

		6) when querySelectorAll select all results to register them in the Observer, I also read their _id attribute. I register their _id in an object, resultsVisibility, initializing everything to false (no result is visible). This object is like a buffer between pure JS and Angular. JS writes in it, and Angular reads from it.

		7) Immediately, the Observer notices all the results currently visible in view (awesome). For every one of them, in the observer's callback, I read their _id attribute, and I update it in the resultsVisibility object (visible true/false)

		8) finally, in the view, I encapsulate the heavy content of each result inside an ng-container, which is rendered (*ngIf) only if the result's _id is set to true inside resultsVisibility. Tadaaaaa!

		9) Don't forget to disconnect the observer with observer.disconnect() whenever we're not in the Results tab (other tab, or component's ngOnDestroy)

		ADVANTAGES :

		The results list can now be huge without impacting much the performance. Apparently, even if the Observer observes a big number of results, the performance is really good. The Observer API seems really efficient.

		DOWNSIDES :

		When socket data is received, the view is refreshed (*ngFor is redrawn) which makes the results list flicker, but that's almost nothing.

	*/

	public observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {

		const l = `IntersectionObserver - `;

		entries.forEach((entry: IntersectionObserverEntry) => {

			// console.log(`${l}`)

			const st13 = entry.target.getAttribute("data-st13");

			this.resultsVisibility[st13] = entry.isIntersecting;
		});
	},
		{
			threshold: 0.1, // Show the results content when the result is at least 20% in the view
			root: document.querySelector('ul.results')
		}
	);

	public resultsVisibility: any = {};

	// ---- END PATENT LAZY SCROLLING ----



	constructor(private ms: MechanicsService,
		public ar: ActivatedRoute,
		public http: HttpClient) {

		const l = `ss.constructor - `


	}



	getDocumentDetails(st13: string, office: string): Promise<GBDSolrResponse> {

		const l = `SS getDocumentDetails() -`

		let params: DocumentsQueryParams = {
			st13: [st13],
			images: true, // Full base64 images instead of only the logo ID
			lang: this.ms.lang // Need the language server-side to hydrate vienna classes
		}

		if (office) {
			params['office'] = [office]
		}

		this.ms.setLoading(true, l);

		let response: Promise<GBDSolrResponse>

		try {
			const url = `${environment.backendUrl || ""}/docsgbd?${queryString.stringify(params)}`;
			response = lastValueFrom(this.http.get(url, { responseType: "text" })) as Promise<GBDSolrResponse>;
		} catch (err) {
			console.error(`${l}Caught error = `, err)
			this.ms.setLoading(false)
			return
		}
		this.ms.setLoading(false)

		return response
	}

	async getIDxDetails(st13: string): Promise<string[]> {

		const l = `SS getDocumentDetails() -`


		let params: DocumentsQueryParams = {
			st13: [st13],
			lang: this.ms.lang // Need the language server-side to hydrate vienna classes
		}

	
		this.ms.setLoading(true, l);

		let response

		try {
			const url = `${environment.backendUrl || ""}/docsidx?${queryString.stringify(params)}`;
			response = await lastValueFrom(this.http.get(url, { responseType: "text" }));
		} catch (err) {
			console.error(`${l}Caught error = `, err)
			return
		}
		response = decrypt(response) as IDXDocument
		let logos = response.response.docs[0].logo || []
		return logos
	}

	async getDbNews(params: any = {}): Promise<DBNews[]> {

		const l = `SS getDbNews() - `


		const route = `${environment.backendUrl || ""}/news`

		let response // :SolrResponse
		try {
			response = await lastValueFrom(this.http.get(route, { responseType: "text" }))
			response = decrypt(response)
		}
		catch (err) {
			//console.log(`${l}Caught error = `, err);
			if (!this.ms.isLocalHost) this.ms.setSearchError(err);
			else{
				return [{
					"news_date": "2099-09-18",
					"uid": 1,
					"title": {
					  "en": "Cambodia data available"
					},
					"payload": {
					  "en": "Over 50,000 records added"
					}
				  }]
			}
			return
		}

		// console.log(`${l}Got dbnews: `, response)

		return response
	}

	async postPersist(type: string, params: any = {}): Promise<any> {

		const l = `SS postPersits() - `


		const route = `${environment.backendUrl || ""}/persist/` + type;

		let response // :SolrResponse
		try {
			response = await lastValueFrom(this.http.post(route, params))
		}
		catch (err) {
			// console.log(`${l}Caught error = `, err);
			if (!this.ms.isLocalHost) this.ms.setSearchError(err);
			return
		}

		// console.log(`${l}Got postPersits: `, response)

		return response
	}



	async deletePersist(params: any = {}): Promise<any> {

		const l = `SS deletePersits() - `


		const route = `${environment.backendUrl || ""}/persist/` + params['type'] + `/` + params['name'];
		let response // :SolrResponse
		// console.log(`${l}called delete persist`, route);


		try {
			response = await lastValueFrom(this.http.delete(route));
		}
		catch (err) {
			// console.log(`${l}Caught error = `, err);
			if (!this.ms.isLocalHost) this.ms.setSearchError(err);
			return
		}

		// console.log(`${l}Got postPersits: `, response)

		return response
	}

	async getPersist(type: string): Promise<any[]> {
		const l = `SS getPersit() - `
		const route = `${environment.backendUrl || ""}/persist/` + type
		let response // :SolrResponse

		try {
			response = await lastValueFrom(this.http.get(route, { responseType: "text" }))
			response = decrypt(response)
		}
		catch (err) {
			// console.log(`${l}Caught error = `, err);
			if (!this.ms.isLocalHost) this.ms.setSearchError(err);
			return []
		}

		// console.log(`${l}Got getPersits: `, response)
		return response
	}


	// send cid if you want to read from cache
	search(payload: any, cid?: string): Observable<string> {

		const l: string = `ss.search() - `

		// console.log(`${l}received payload = `, payload)

		try {
			/*	
				Dirty fix for :
				- If you pass "rows" to Solr on the /explore page, it returns no facets, so explore page
				- But you need to pass "rows" on /explore/results, which displays a list of results and uses the same "/explore" endpoint

				So : if we are on "/explore/results" I leave rows, but on "/explore/visu" I remove them
			*/
			if (this.ar.snapshot["_routerState"]["url"].endsWith("/explore/visu")) {
				delete payload.rows
				// console.log(`${l}Have removed Rows from payload.`)
			}
		} catch (err) {
		}

		// get from cache if identifier matches
		if (cid && cid === this.searchResult.cid) {
			// console.log(`${l} reading results from cache`);
			// setTimeout(()=> of(this.searchResult.currentResult), 0)
			return of(this.searchResult.currentResult).pipe(delay(100))
		}

		// Mona : removing the start=0 qp to maximize the use of browser cache
		if (payload['start'] === '0') delete payload['start'];

		if (typeof payload.asStructure === "object") {
			payload.asStructure = JSON.stringify(payload.asStructure); // the /advancedsearch back-end endpoint expects a stringified asStructure, in order to count how many * it has
		}

		// Facet Groups : pre-determined facets (see api.yml). Avoids to have to pass each facet individually.
		// Can then be overriden individually (fcstatus="Registered" etc)
		if (!payload["fg"]) {
			payload["fg"] = "explore-all"
		}
		let route;

		switch (this.ms.endpoint) {
			case "explore":
			case "quicksearch":
			case "similarlogo":
			case "similarname":
			case "goodsservices":
			case "advancedsearch":

				route = `${environment.backendUrl || ""}/search`;
				if (this.ms.officeCC) {
					payload["office"] = this.ms.officeCC
					payload["fcoffice"] = this.ms.officeCC
				}
				//if (this.ms.isBeta) {
				//	payload["office"] = 'beta'
				//}
				return this.http
					.post(route, payload, { responseType: "text" }) // "text" because encrypted now
					.pipe(distinctUntilChanged(), debounceTime(500))

			default: // coverage, dbinfo, docsgbd, docsidx, suggest, etc.
				route = `${environment.backendUrl || ""}/${this.ms.endpoint}`;
				//if (this.ms.isBeta) {
				//	payload["office"] = 'beta'
				//}
				return this.http.get(`${route}?${queryString.stringify(payload)}`, { responseType: "text" }) // "text" because encrypted now
		}
	}

	getCoverage(): Promise<any> {
		const l = `getCoverage() -`
		let route = `${environment.backendUrl || ""}/coverage`;
		//if (this.ms.isBeta) {
		//	route = route + '?office=beta'
		//}
		return lastValueFrom(this.http.get(route))
	}

	observeResults(docs?: any[], reset = true) {

		const l = `observeResults() - `

		/*
			This function waits for all *ngFor elements to be built, then attaches them to the IntersectionObserver, so that we know when they are visible, and lazily render their content
		*/


		docs = docs || this.searchResult.docs;
		docs.forEach(element => {
			if(this.ms.isInCache(element.st13)){
				element.selected = true
			}
			if(this.ms.viewedSt13sCache.includes(element.st13)){
				element.viewd = 'viewed'
			}
		});

		// console.log(`${l}Will observe results after they are built - docs = `, docs)

		// console.time(`${l} All results built in`);

		if (reset) {

			/*
			In the case of reports, I don't want all watchlist to be reset every time a report is hydrated, I need to make it cumulative. When called from the reports page, "reset" is disabled
			*/

			this.observer.disconnect();
			this.resultsVisibility = {};
		}

		setTimeout(() => { // This will be triggered after *ngFor synchronously creates <li> elements (empty for now, so that's fast)

			// console.timeEnd(`${l} All results built in`)

			// console.time(`${l} Found results in DOM in `);

			// Hack : adding the .filter() method to NodeList
			if (window.NodeList && !NodeList.prototype["filter"]) {
				NodeList.prototype["filter"] = Array.prototype.filter;
			}

			// Processing only results without a data-st13 attribute (because this means they are already watched)
			let resultsNodes: Element[] = Array.from(document.querySelectorAll("ul.results li.result"));

			// console.timeEnd(`${l} Found results in DOM in `); // 0.05 ms (!)

			// console.log(`${l}resultsNodes.length = ${resultsNodes.length}`)

			resultsNodes.forEach((elem: Element, index) => {
				const st13 = docs[index].st13;
				elem.setAttribute("data-st13", st13)
				this.resultsVisibility[st13] = false;
				this.observer.observe(elem);
			})

			// console.log(`${l}Observing result list visibility`)
		})

	}
}
