import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageHelpComponent } from './pages/page-help/page-help.component';

const routes: Routes = [

	{ path: ':lang/vienna', 
		loadChildren: async () => (await import("./pages/page-redirect/page-redirect.module")).PageRedirectModule
	},
	{ path: ':lang/gs',
		loadChildren: async () => (await import("./pages/page-redirect/page-redirect.module")).PageRedirectModule

	},
	{ path: ':lang/:office/vienna', 
		loadChildren: async () => (await import("./pages/page-redirect/page-redirect.module")).PageRedirectModule
	},
	{ path: ':lang/:office/gs',
		loadChildren: async () => (await import("./pages/page-redirect/page-redirect.module")).PageRedirectModule
	},
	
	{
		/*
			QUICK SEARCH
		*/
		path: `:lang/quicksearch`,
		loadChildren: async () => (await import("./tabs/1-quicksearch/page-quicksearch.module")).PageQuicksearchModule
	},
	{
		/*
			QUICK SEARCH - but only for a specific office
		*/
		path: `:lang/:office/quicksearch`,
		loadChildren: async () => (await import("./tabs/1-quicksearch/page-quicksearch.module")).PageQuicksearchModule
	},

	{
		/*
			SEARCH BY SIMILAR LOGO
		*/
		path: ':lang/similarlogo',
		loadChildren: async () => (await import("./tabs/3-byLogo/page-similarlogo.module")).PageSimilarlogoModule
	},
	{
		/*
			SEARCH BY SIMILAR LOGO - only for a specific office
		*/
		path: `:lang/:office/similarlogo`,
		loadChildren: async () => (await import("./tabs/3-byLogo/page-similarlogo.module")).PageSimilarlogoModule
	},

	{
		/*
			SEARCH BY SIMILAR NAME
		*/
		path: ':lang/similarname',
		loadChildren: async () => (await import("./tabs/2-byName/page-similarname.module")).PageSimilarnameModule,
	},
	{
		/*
			SEARCH BY SIMILAR NAME - only for a specific office
		*/
		path: ':lang/:office/similarname',
		loadChildren: async () => (await import("./tabs/2-byName/page-similarname.module")).PageSimilarnameModule,
	},

	{
		/*
			EXPLORE PAGE
		*/
		path: `:lang/explore/graphs`,
		redirectTo: ":lang/explore/visu"
	},
	{
		/*
			EXPLORE PAGE - only for a specific office
		*/
		path: `:lang/:office/explore/graphs`,
		redirectTo: ":lang/:office/explore/visu"
	},

	{
		/*
			VISU
		*/
		path: `:lang/:endpoint/visu`,
		loadChildren: async () => (await import("./tabs/6bis-visu/page-visu.module")).PageVisuModule
	},
	{
		/*
			VISU - only for a specific office
		*/
		path: `:lang/:office/:endpoint/visu`,
		loadChildren: async () => (await import("./tabs/6bis-visu/page-visu.module")).PageVisuModule
	},

	{
		/*
			REPORTS PAGE
		*/
		path: `:lang/reports`,
		loadChildren: async () => (await import("./tabs/7-reports/page-reports.module")).PageReportsModule
	},
	{
		/*
			REPORTS PAGE
		*/
		path: `:lang/:office/reports`,
		loadChildren: async () => (await import("./tabs/7-reports/page-reports.module")).PageReportsModule
	},

	{
		/*
			COVERAGE PAGE
		*/
		path: `:lang/coverage`,
		loadChildren: async () => (await import("./tabs/8-coverage/page-coverage.module")).PageCoverageModule
	},
	{
		/*
			COVERAGE PAGE
		*/
		path: `:lang/:office/coverage`,
		loadChildren: async () => (await import("./tabs/8-coverage/page-coverage.module")).PageCoverageModule
	},

	{
		/*
			RESULTS PAGE
		*/
		path: `:lang/:endpoint/results`, // /en/quicksearch/results
		loadChildren: async () => (await import("./pages/page-results/page-results.module")).PageResultsModule
	},
	{
		/*
			RESULTS PAGE (OFFICE)
		*/
		path: `:lang/:office/:endpoint/results`, // /en/quicksearch/results
		loadChildren: async () => (await import("./pages/page-results/page-results.module")).PageResultsModule
	},

	{
		/*
			DETAILS PAGE without endpoint (when open in a new tab)
		*/
		path: ':lang/brand/:st13',
		pathMatch: 'full',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},
	{
		/*
			DETAILS PAGE
		*/
		path: ':lang/:endpoint/brand/:st13',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},
	{
		/*
			DETAILS PAGE (OFFICE)
		*/
		path: ':lang/:office/:endpoint/brand/:st13',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},

	{
		/*
			ADVANCED SEARCH PAGE
		*/
		path: `:lang/advancedsearch`,
		loadChildren: async () => (await import("./tabs/5-advancedSearch/page-advancedSearch.module")).PageQueryBuilderModule
	},
	{
		/*
			ADVANCED SEARCH PAGE (OFFICE)
		*/
		path: `:lang/:office/advancedsearch`,
		loadChildren: async () => (await import("./tabs/5-advancedSearch/page-advancedSearch.module")).PageQueryBuilderModule
	},

	{
		/*
			HELP / DOCUMENTATION PAGE
		*/
		path: ':lang/help',
		component : PageHelpComponent,
	},

	{
		/*
			NEWS PAGE
		*/
		path: `:lang/news`,
		loadChildren: async () => (await import("./pages/page-news/page-news.module")).PageNewsModule
	},

	{
		/*
			COVERAGE PAGE
		*/
		path: `:lang/saved/:uid`,
		loadChildren: async () => (await import("./pages/page-saved-searches/page-saved-searches.module")).PageSavedSearchesModule
	},
	{
		/*
			EXPORTING search results to PDF or Excel (works for quicksearch, similarlogo, similarname, reports)
		*/
		path: `:lang/:endpoint/export`,
		loadChildren: async () => (await import("./pages/page-export/page-export.module")).PageExportModule
	},
	{
		/*
			EXPORTING search results to PDF or Excel (works for quicksearch, similarlogo, similarname, reports)
		*/
		path: `:lang/:office/:endpoint/export`,
		loadChildren: async () => (await import("./pages/page-export/page-export.module")).PageExportModule
	},
	{
		/*
			ERROR - NOT FOUND PAGE
		*/
		path: `:lang/notfound`,
		loadChildren: async () => (await import("./pages/page-notfound/page-notfound.module")).PageNotfoundModule
	},

	/*
		REDIRECTIONS AND DEFAULT ROUTES
	*/

	{ path: ':lang', redirectTo: ":lang/similarname", pathMatch: 'full' },

	{ path: ':lang/', redirectTo: ":lang/similarname", pathMatch: 'full' },

	{ path: ':lang/:office', redirectTo: ":lang/:office/similarname", pathMatch: 'full' },

	{ path: ':lang/:office/', redirectTo: ":lang/:office/similarname", pathMatch: 'full' },

	{ path: '', redirectTo: "en/similarname", pathMatch: 'full' },

	{ path: 'logout', redirectTo: "en/similarname", pathMatch: 'full' },

	{
	  path: '**',
		loadChildren: async () => (await import("./pages/page-notfound/page-notfound.module")).PageNotfoundModule
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		enableTracing: false,
		initialNavigation: 'disabled' // initial navigation happens after doSSO() in auth.service
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
