import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';
import { DBNews } from 'src/app/interfaces';



@Component({
	selector: 'page-news',
	templateUrl: './page-news.component.html',
	styleUrls: ['./page-news.component.css']
})
export class PageNewsComponent implements OnInit {

	public news: DBNews[]



	constructor(private ss: SearchService,
		private qs: QueryParamsService,
		public ms: MechanicsService,
		private router: Router) {

	}

	async ngOnInit(): Promise<void> {

		const l = `PageNews ngOnInit() - `

		this.ms.setEndpoint('news')

		await this.getNews()
		this.ms.setLoading(false)
	}

	async getNews() {

		const l: string = `quickSearchComponent getNews() - `

		let news: DBNews[] = await this.ss.getDbNews()
		
		this.news = (news || []).map( n => {
			n["news_date"] = this.ms.dateFormatterHuman.format(new Date(n["news_date"]));
			return n
		})
	}

	get_news_for_lang(value:{}): string{
		if(value){
			return value[this.ms.lang] || value["en"]
		}
		return ''
	}

	
}
