import { ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import {v4 as uuidv4} from 'uuid';

import { environment } from "../environments/environment"
import { AuthService } from './_services/auth.service';
import { MechanicsService } from './_services/mechanics.service';
import { OptionsListService } from './_services/options-list.service';
import { PreferencesService } from './_services/preferences.service';
import { QueryParamsService } from './_services/queryParams.service';
import { RecentSearchesService } from './_services/recentSearches.service';
import { ReportsService } from './_services/reports.service';
import { SearchService } from './_services/search.service';
import { DBNews } from './interfaces';
import { HttpClient } from '@angular/common/http';


declare const SmartlingContextTracker

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

	public environment = environment
	
	public logsSettings: any = {
		isExists: false,
		align: "left",
		minimize: false
	}
	public hashSearches: string = localStorage.getItem("gbd.hashSearches")

	public helpOptions: any[] = [];
	public helpMore: any[] = [];
	public isOpen = true;
	public is_news_closed: boolean = false

	constructor(
		private router: Router,
		public ms: MechanicsService,
		public ss: SearchService,
		public rss: RecentSearchesService,
		public ps: PreferencesService,
		public qs: QueryParamsService,
		public rs: ReportsService,
		private authService: AuthService,
		public ols:OptionsListService,
		public http: HttpClient,
		private changeDetector: ChangeDetectorRef) {

		this.logsSettings.isExists = !this.ms.isAwsProd;
	}

	select_with_lang(value){
		return value ? (value[this.ms.lang] || value["en"]) : ''
	}

	get_news_for_lang(): string {
		if (this.ms.news.length == 0)
			return
		let one_week_ago = new Date(Date.now() - 604800000);
		if(new Date(this.ms.news[0].news_date) < one_week_ago){
			this.ms.show_news = false
			return
		}
		return `<i>${this.ms.news[0].news_date}</i>:&nbsp;&nbsp;<b>${this.select_with_lang(this.ms.news[0].title)}</b> - ${this.select_with_lang(this.ms.news[0].payload)}`
	}

	async getNews() {

		const l: string = `quickSearchComponent getNews() - `

		try {
			let news: DBNews[] = await this.ss.getDbNews()

			this.ms.news = news.slice(0, 1).map(n => {
				n["news_date"] = this.ms.dateFormatterHuman.format(new Date(n["news_date"]));
				return n
			})
		}
		catch (err) {
			// console.log(`${l}Caught error : `, err)
		}
	}

	async ngOnInit() {
		this.ngOnInitNoBot()
		/*
		load()
			.then((botd) => botd.detect())
			.then((result) => {
				if(result.bot == false)
				else{
					alert("You have been identified as an automated connection, which is against the Terms of Use of the application.")
				}
			})
			.catch((error) => {
				alert("You have been identified as an automated connection, which is against the Terms of Use of the application.")
				console.error(error)
			});
		*/
	}

	// hcaptcha

	async onVerify(ev: any) {
		if (ev.type === 'verified') {
			let token = ev.detail.payload
			this.hashSearches = uuidv4()
			localStorage.setItem("gbd.hashSearches", this.hashSearches)
			let res = await this.ols.getDbInfo({token})
			if(res){
				window.location.reload();
			}
		}
	}		
	
	onExpired(response: any) {
		// The verification expired.
	}
	
	onError(error: any) {
		// An error occured during the verification process.
	}

	async ngOnInitNoBot(){
	
		const l = `app.component ngOnInit() - `
		

		if (this.ms.isAwsAcc) this.loadSmartlingContext() // only on AWS acceptance

		let state: string = window.location.pathname + window.location.search;

		this.buildHelpMenu()
		state = await this.authService.doSSO(); // Too many errors in the console, I'm cleaning this up

		this.loadWipoNavbar()

		// do fake captcha request for auth users

		let route = `${environment.backendUrl || ""}/captcha?t=` +  localStorage.getItem("gbd.hashSearches")
		this.http.get(`${route}`).subscribe({

			next: (payload: string) => {
			}
		})

		let oldLocation = localStorage.getItem("gbd.lggedFrom")
		if(oldLocation){
			localStorage.removeItem("gbd.lggedFrom")
			window.location.href = oldLocation
		}

		const recentSearches: string = localStorage.getItem("gbd.recentSearches")

		if (recentSearches){
			let evtDetail = { detail: {"value":1,"text":"gbd_returing_visitor"}}
			let evt = new CustomEvent("wipoPing", evtDetail );
			document.dispatchEvent(evt);
		}
		// console.log(`${l}state=`, state)

		this.router.navigateByUrl(state, {
			skipLocationChange: false,
			replaceUrl: true
		})

		const logsSettingsString = localStorage.getItem("gbd.logsSettings");

		if (logsSettingsString) {

			try {
				let logsSettings = JSON.parse(logsSettingsString);
				this.logsSettings = Object.assign(this.logsSettings, logsSettings)
				// console.log(`${l}this.logsSettings=`,this.logsSettings)

			} catch (err) {
				// console.log(`${l}Could not restore logs settings, ignoring - Full err =`, err)
			}
		}
		await this.getNews()
	}
	

	ngAfterViewChecked() {

		/*
			While in development, the console can get filled with very annoying "error" messages saying that some variable has changed since last time it was checked (??)
			Some Angular weird stuff.
			
			Apparently, calling changeDetector.detectChanges() prevents those.
			
			It doesn't happen in prod build, so I'm only doing it in dev


			--> Update 2022-11-03 : Extremely tenacious bug I've spent hours to chase.
			on localhost and awsDev, all was working fine. But in acc and uat, we were only getting blank pages and unrefreshed templates. Uncommenting this and leaving changeDetector.detectChanges() in all environment seems to fix the problem. So LEAVE THIS ON!
		*/

		this.changeDetector.detectChanges();
	}


	public getPageCoverageTitle(): string {
		let title = this.ms.translate("page_coverage.data_coverage")
		
		/*
		if (this.ms.officeCC) {
			title = `${title} - ${this.ms.officeCC}`
		}*/

		return title
	}

	async buildHelpMenu() {

		const l = `appcomponent buildHelpMenu() - `
		let antiloop = 0
		// console.log(`${l}Rebuilding help menu`)
		while (!this.ms.translations && antiloop < 50) {
			await new Promise(r => setTimeout(r, 200));
			antiloop++;
		}
		

		this.helpOptions = [  // Optional, set custom links for Contact and FAQ  (default = WIPO CONTACT)
			{
				"code": "contact", // - code property is mandatory
				"link": `https://www3.wipo.int/contact/${this.ms.lang}/area.jsp?area=branddb` // - link property is optional
			},
			{
				"name": "FAQs",
				"code": "faq",
				"link": environment.FAQUrl.replace("{lang}", this.ms.lang),
				"targetTab": "_self"
			}
		];

		this.helpMore = [
			{
				"name": this.getPageCoverageTitle(),
				"code": "coverage",
				"link": this.ms.makeRoute({ path: 'coverage', subpath: '', includeOffice: false }),
				"targetTab": "_self"
			},
			{
				"name": this.ms.translate("help_menu.news"),
				"code": "news",
				"link": `${environment.appUrl}/${this.ms.lang}/news`,
				"targetTab": "_self"
			},
			{
				"name": this.ms.translate("help_menu.user_reference_guide"),
				"code": "guide",
				"link": `${environment.appUrl}/assets/gbd_doc.pdf`,
				"targetTab": "_self"
			},

			{
				"name": this.ms.translate("help_menu.terms_of_use"),
				"code": "terms",
				"link": `https://www.wipo.int/reference/${this.ms.lang}/branddb/terms_and_conditions.html`,
				"targetTab": "_self"
			},

		];
	}
	loadWipoNavbar(): void {

		const l = `appcomponent loadWipoNavbar() - `
		let counter = 0;

		const wipoComponentsPath: string = environment.wipoComponentsPath;
		const scripts = [
			`${wipoComponentsPath}/polyfills/webcomponents-loader.js`,
			`${wipoComponentsPath}/wipo-init/wipo-init.js`,
			`${wipoComponentsPath}/wipo-navbar/wipo-navbar.js`
		]
		if (!wipoComponentsPath) {
			// console.log(`${l}No wipoComponentsPath, not loading the WIPO navbar`)
			return
		}

		// console.log(`${l}wipoComponentsPath : `, wipoComponentsPath);

		scripts.forEach(src => {
			document.head.appendChild(Object.assign(document.createElement('script'), {
				src,
				async: true,
				onload: async () => {
					counter += 1;
					let waitForPing = true
					let retryies = 0
					if (counter == scripts.length){
						while(waitForPing && retryies < 100){
							retryies += 1
							await new Promise(r => setTimeout(r, 100))
							Array.from(document.head.getElementsByTagName("*")).forEach(element => {
								if(element.nodeName == 'LINK'){
									if(element.getAttribute('href').includes('noto-sans-kr-weights.css'))
										waitForPing = false
								}
							});
						}
						this.sendPingToAnalytics()
					}

				}
			}));
		});
	};

	sendPingToAnalytics(){
		const recentSearches: string = localStorage.getItem("gbd.recentSearches")
		if (recentSearches){
			let evtDetail = { detail: {"value":1,"text":"gbd_returning_visitor"}}
			let evt = new CustomEvent("wipoPing", evtDetail );
			document.dispatchEvent(evt);
		}	
	}
	
	loadSmartlingContext() {
		var script = document.createElement('script');
		script.onload = function () {
			SmartlingContextTracker.init({
				orgId: 'LR3HwdNcfBVS269bS54AUQ',
				host: 'api.smartling.com'
			});
		};
		script.src = "//d2c7xlmseob604.cloudfront.net/tracker.min.js";
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	get windowWidth(): number {
		return window.innerWidth
	}

	setLogsSetting(key: string, value: any) {

		const l = `appComponent setLogsSetting() - `

		// console.log(`${l}setting logsSettings[${key}] = ${value}`)

		this.logsSettings[key] = value;

		localStorage.setItem("gbd.logsSettings", JSON.stringify(this.logsSettings))
	}

	toggleDummyLogos($event) {

		const l = `compResultsInfo toggleDummyLogos() - `

		const isChecked: boolean = $event.target.checked

		// console.log(`${l}isCheckd = `, isChecked)

		localStorage.setItem("showDummyImages", "" + isChecked)

	}

	get isDummyLogos() {
		return localStorage.getItem("showDummyImages") === "true"
	}
}

