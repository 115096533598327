import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PaginationEvent } from 'src/app/interfaces';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';


@Component({
	selector: 'jer-paginator',
	templateUrl: './jer-paginator.component.html',
	styleUrls: ['./jer-paginator.component.css']
})
export class JerPaginatorComponent {

	@Input() totalItemsCount: number = 0

	@Output() pageChange = new EventEmitter<PaginationEvent>();

	public currentPage: number = 0
	public totalPagesCount: number = 0

	constructor(private ps: PreferencesService,
		private qs:QueryParamsService,
		private qp: QueryParamsService) {

	}

	ngOnInit() {

		const l = `jerPaginator ngOnInit() - `

		const start: number = +this.qp.getQP("start") || +this.ps.getPref("start") || 0;

		// console.log(`${l}start = ${start}`)

		if (start === 0) {

			this.currentPage = 1;

		} else {

			const rows: number = +this.qp.getQP("rows") || +this.ps.getPref("rows") || 30;

			// console.log(`${l}rows = ${rows}`)

			this.currentPage = Math.floor(start / rows) + 1;
		}

	}

	ngOnChanges(changes: SimpleChanges) { // detects when @Input() changes :D
		const l = `jerPaginator ngOnChanges - `
		// console.log(`${l}changes=`, changes)

		if (changes.totalItemsCount.currentValue === 0) {
			this.currentPage = 1
		}

		this.buildPages()
	}

	buildPages() {

		const l = `jerPaginator buildPages()`

		// console.log(`${l}totalItemsCount = ${this.totalItemsCount}`)

		this.totalPagesCount = this.totalItemsCount / this.itemsPerPage

		this.totalPagesCount = Math.ceil(this.totalPagesCount)
		// console.log(`${l}totalPagesCount = ${this.totalPagesCount}`)

		if (this.currentPage > this.totalPagesCount) this.currentPage = 1

	}

	onButtonClicked(which: string) {

		const l = `jerPaginator onButtonClicked() - `

		switch (which) {
			case "first":
				this.currentPage = 1;
				break;
			case "prev":
				this.currentPage--
				if (this.currentPage < 1) this.currentPage = 1;
				break;
			case "next":
				this.currentPage++
				if (this.currentPage > this.totalPagesCount) this.currentPage = this.totalPagesCount;
				break;
			case "last":
				this.currentPage = this.totalPagesCount;
				break;
		}

		const toEmit: PaginationEvent = {
			currentPage: this.currentPage,
			skip: (this.currentPage - 1) * this.itemsPerPage
		}

		this.pageChange.emit(toEmit)
	}

	get itemsPerPage(): number {
		return +(this.qs.getQP("rows") || this.ps.getPref("rows") || 30)
	}
}
