import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MechanicsService } from '../../_services/mechanics.service';

const sources = {

	// For now, we only have documentation in English, so all links point to the English version. When docs are available in other languages, update their links here

	en: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
	fr: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
	es: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
	ru: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
	ar: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
	zh: "https://docs.google.com/document/d/e/2PACX-1vRzK0ReJfOFZL19Ndj9-WsDu1iHerosWy7NhaQtZl6RVz8K4MNilWgfFOoSiJ_TktmJJlIfJ89ZZNgw/pub?embedded=true",
}



@Component({
	selector: 'page-help',
	templateUrl: './page-help.component.html',
	styleUrls: ['./page-help.component.css']
})
export class PageHelpComponent implements OnInit {

	docUrl: SafeResourceUrl

	constructor(public ms: MechanicsService,
		public sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		this.setDocUrl()
	}


	setDocUrl() {

		let docUrl = sources[this.ms.lang]

		if (!docUrl) {
			alert(`No documentation for language '${this.ms.lang}'! Using English`)
			docUrl = sources["en"]
			return
		}

		// console.log(`docUrl = `, this.docUrl);

		this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(docUrl)
	}

}
