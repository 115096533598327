import { Component, OnChanges, SimpleChanges, Input, } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { OptionsListService } from 'src/app/_services/options-list.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { DBInfoSolrResponse } from 'src/app/interfaces';

@Component({
	selector: 'queryParams2human',
	templateUrl: './queryParams2human.component.html',
	styleUrls: ['./queryParams2human.component.css']
})
export class QueryParams2humanComponent implements OnChanges {

	// sent in the results page
	@Input() query?: any
	@Input() facets?: any

	// sent in the quick-search page for recentSearches
	@Input() searchObj?: any

	@Input() interactive?: boolean = false // interact with facets
	@Input() endpoint: string // always sent

	// public predicates: { key: string, label: string, value: string }[]; // Predicates aren't in use any longer, since advancedSearch
	public facetsBadges: any[];

	public asStructure: any // AdvancedSearch object, in case of an Advanced Search

	// public thumbnailBase64: string = ""
	public recordsCount: string = null
	public dataSourcesCount: string = null

	get windowWidth(): number {
		return window.innerWidth
	}

	get welcomeSpeech(): string {
		let office = this.ms.officeCC
		// the search engine is configured for an office
		if (office) {
			const welcome_msg = 'page_quick_search.welcome_speech_peroffice' + (["WHO", "LISBON", "6TER"].includes(office) ? `_${office}` : '');

			return this.ms.translate(welcome_msg)
				.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
				.replace('{c}', this.ms.translate('designation.' + office))
				.replace('{l}', this.ms.translate('office.' + office))
		}

		return this.ms.translate('page_quick_search.welcome_speech')
			.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
			.replace('{d}', this.dataSourcesCount)
	}
	constructor(public ms: MechanicsService,
		public ols: OptionsListService,
		public qs: QueryParamsService) {
		
	}

	async getCounts() {

		const l: string = `querparams2human.getCounts() - `
		let dbinfo: DBInfoSolrResponse = await this.ols.getDbInfo({ caller: l })
	
		this.recordsCount = "" + dbinfo?.facets.count
		this.dataSourcesCount = "" + dbinfo?.facets?.office.buckets.length
		
	}


	ngOnInit() {

		const l = `querparams2human.ngOnInit() - `


		// console.log(`${l}@Input() query =`, this.query)
		// console.log(`${l}@Input() searchObj =`, this.searchObj)

		const asStructure = this.searchObj?.asStructure || this.qs.getQP("asStructure");
		
		// console.log(`${l}this.searchObj?.asStructure = `, this.searchObj?.asStructure)
		// console.log(`${l}this.qs.getQP("asStructure") = `, this.qs.getQP("asStructure"))
		
		if (asStructure) {
			// console.log(`${l}asStructure = `,asStructure)
			if (typeof (asStructure) === "object") { // It's already a valid object, on page-advanced-search
				this.asStructure = asStructure;
				// console.log(`${l}Found valid asStructure`)
			} else {
				try {
					this.asStructure = JSON.parse(asStructure)
				} catch (err) {
					// console.log(`${l}Could not parse asStructure! Maybe it's already parsed? `, asStructure)
				}
			}
			// console.log(`${l}this.asStructure is now = `, this.asStructure)
		}
		if (asStructure.bricks && asStructure.bricks.length==0){
			this.getCounts() 
		}
		
	}


	ngOnChanges(changes: SimpleChanges) {

		const l = `querparams2human.ngOnChanges() - `

		//console.log(`${l}changes=`, changes)

		if (changes.searchObj) {

			// scenario 1: recent search object
			// recent searches react of firstChange (called explicitly)
			const qpc = this.qs.queryParams2Object(changes.searchObj.currentValue)

			this.buildBadges(qpc.facets)

		} else {

			// scenario 2: results page results-info
			// search info reacts on second change and thereafter (called implicitly from comp-results-info)

			// console.log(`${l}changes.query.currentValue = `, deepClone(changes.query.currentValue))

			// rebuild facets everytime they change
			if (changes.facets && !changes.facets.firstChange) {
				this.buildBadges(changes.facets.currentValue)
			}
			if (changes.query && !changes.query.firstChange) {
				this.asStructure = JSON.parse(changes.query.currentValue.asStructure)
			}
		}

	}

	// show the translated value (for controlled search input and facet values)
	private valuesToTranslate = [
		"markFeature", "status", "type", "strategy", "by",
	]

	private valuesToHandle = [
		"applicationDate", "registrationDate", "expiryDate",
		"applicantCountryCode", "office", "designation", "base64"
	]


	private _applicationDate_handler(value: string): string {
		return this.__date_handler(value)
	}
	private _registrationDate_handler(value: string): string {
		return this.__date_handler(value)
	}
	private _expiryDate_handler(value: string): string {
		return this.__date_handler(value)
	}


	// applicationDate => F2015-06T2015-06 => 2015-06
	//                 => F2015-06T2019-11 => 2015-06 to 2019-11
	//                 => 2012 => 2012

	private __date_handler(value: string): string {
		// this is a range
		if (value.startsWith("F")) {
			let start = value.substring(1, value.indexOf("T") - 1)
			let end = value.substring(value.indexOf("T") + 1, value.length - 1)

			if (start === end) return start
			else return `${start} &rarr; ${end}`
		}
		// this is a year
		else {
			return value
		}
	}

	// applicantCountryCode => translate using designation
	private _applicantCountryCode_handler(value: string): string {
		return `${value} (${this.ms.translate('designation.' + value)})`
	}
	private _designation_handler(value: string): string {
		return `${value} (${this.ms.translate('designation.' + value)})`
	}
	private _office_handler(value: string): string {
		return `${value} (${this.ms.translate('office.' + value)})`
	}

	get thumbnailBase64() {
		if(this.searchObj) {
			return this.searchObj['bases64'] && this.searchObj['bases64'][0]
		}
		return this.ms.bases64 && this.ms.bases64[0]
	}

	get strategy() {
		if(this.searchObj) {
			return this.searchObj['strategy']
		}
		return this.qs.getQP('strategy')
	}

	buildBadges(facets: any): void {
		const l = `querparams2human.buildBadges() - `

		// console.log(`${l}passed facets = `, facets)

		/*
			passed facets =  {
				"fcstatus": [
					"Registered",
					"Ended"
				],
				"fcdesignation": [
					"SM",
					"WS",
					"DE"
				],
				"fcoffice": [
					"SD",
					"ID",
					"IS"
				],
				"fctype": [
					"TRADEMARK",
					"EMBLEM"
				],
				"fckind": [
					"Individual"
				],
				"fcmarkFeature": [
					"Word",
					"Figurative",
					"Combined"
				],
				"fcniceClass": [
					"5",
					"42",
					"36"
				],
				"fcapplicationDate": [
					"2018",
					"2019",
					"2020",
					"2021"
				]
			}

		*/


		this.facetsBadges = []

		facets:
		for (let key of Object.keys(facets)) { // key="fcdesignation"

			// facets are always sent as arrays
			let values = facets[key]; // [ "FR", "ES", "!DK",'!Registered', '!Expired']

			key = key.replace(/^fc/, ""); // "fcmarkFeature" --> "markFeature"
			let displayedLabel = this.ms.translate('general_words.' + key);
			// console.log(`${l}displayedLabel='${displayedLabel}'`)

			for (let value of values) {

				// Value can be negated ("!Pending", "!World Health Organization")
				const isNegated: boolean = value.startsWith("!");
				let displayedValue = value.replace(/^!/, "");

				// translate the value if needed
				if (this.valuesToTranslate.includes(key)) {

					const toTranslate = key + '.' + displayedValue;
					// console.log(`${l}toTranslate='${toTranslate}'`) // 'status.Registered'
					displayedValue = this.ms.translate(toTranslate) // Translating the non-negated value (status.Pending and status.!Pending both yield "Pending")
				}

				if (this.valuesToHandle.includes(key)) { // Mona - doesn't take negation into account.........
					displayedValue = this[`_${key}_handler`](displayedValue)
				}

				if (isNegated) { // Jer
					displayedValue = this.ms.translate("general_words.negation") + " " + displayedValue; //- !INN --> Not INN, !Pending -> Not Pending
				}

				// console.log(`${l}displayedValue=${displayedValue}`)

				if (displayedValue === "_void_") {
					displayedValue = this.ms.translate("general_words.missing_value")
				}

				this.facetsBadges.push({
					key,
					value,
					label: displayedLabel + ": " + displayedValue
				})

			}


		}
		// console.log(`${l}facetsBadges = `, this.facetsBadges)
	}

	displayValue(brick: any): string {

		const l = `qs.displayValue() - `

		let key: string = brick.key;
		let value: string | string[] | WOption[] = brick.value

		// console.log(`${l}key=`, key, `value = `, value)

		/* Can't be a Date object; We can't use Date objects in query params, we need ISO8601 (YYYY-MM-DD)

			if (value instanceof Date) {
				return this.ms.dateFormatterHuman.format(value);
			}
		*/

		if(!value) return ""; // Failsafe to avoir thousands of errors in the console

		if (/date/i.test(key)) {

			try {

				if (Array.isArray(value) && value.length === 2) { // Special case : date range

					value = value as string[]
					// console.log(`${l}date range detected - value[0] = '${value[0]}' - value[1] = '${value[1]}' `)

					const toReturn: string = [
						this.ms.translate("general_words.from"),
						value[0] ? this.ms.dateFormatterHuman.format(new Date(value[0])) : "?",
						this.ms.translate("general_words.to"),
						value[1] ? this.ms.dateFormatterHuman.format(new Date(value[1])) : "?",
					].join(" ")

					// console.log(`${l}formatted date range = `, toReturn)
					return toReturn
				}

				// Simple ISO8601 date
				return this.ms.dateFormatterHuman.format(new Date("" + value))

			} catch (err) {
				// Date hasn't been selected yet
				return "?"
			}
		}

		if (typeof (value) === "string") return `'${value}'`;

		// OK so now, value is an array. But it can be an array of strings or an array of WOption with { label, value }

		if (!(value as any[]).length) return "";

		if ((value as WOption[])[0].label) {
			// We got a WOption array to display (for instance when you click in the suggestion list), so there's a label and a value. Since the label is supposed to be already translated, I can simply return the label.
			return (value as WOption[]).map(obj => obj.label).join(", ")
		}

		// Last possible case : string[]

		return (value as string[])
			.map(word => this.ms.translate(`${key}.${word}`))
			.join(", ");
	}

}
