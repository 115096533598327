import { Component, OnInit } from '@angular/core';
import { ResultsComponent } from './results/results.component';

@Component({
	selector: 'page-results',
	templateUrl: './hoc-page-results.component.html',
	styleUrls: ['./hoc-page-results.component.css']
})
export class PageResultsComponent implements OnInit {
	contentComponent: any;

	constructor() { }

	ngOnInit() {
		this.contentComponent = ResultsComponent;
	}


}

