import { Pipe, PipeTransform } from '@angular/core';
import { deepClone } from '../utils';
import { PreferencesService } from '../_services/preferences.service';
import { MechanicsService } from '../_services/mechanics.service';

@Pipe({
	name: 'tableSort'
})
export class TableSortPipe implements PipeTransform {


	constructor(private ms: MechanicsService,
		private ps: PreferencesService) {

	}

	transform(series: any[], ...args: unknown[]): unknown {

		const l = `| tableSort - `

		const columnSortBy: string = args[0] as string;
		const columnSortOrder: number = args[1] as number;
		const selectedGraph = this.ps.getPref('chart', 'graphs');

		const toReturn = deepClone(series || [])

		
			/*
						.filter(obj => {
							console.log(`${l}obj.fvalue=${obj.fvalue}`)
							// #temporaryThatLasts - hiding odd things that come back from Solr : Nice classes greater than 45, "--" country code, _void_ values, etc.
							if(['_void_',"--"].includes(obj.fvalue)) return false;
							if(selectedGraph==="niceClass" && obj.fvalue > 45) return false;
							return true
						})
			*/

			.map(obj => {

				// console.log(`${l}obj = `, obj)
				// console.log(`${l}selectedGraph = ${selectedGraph}`)
				// obj == {value: 249595, name: 'AE', fvalue: 'AE', itemStyle: {…}}

				obj.label = obj.fvalue;

				switch (selectedGraph) {
					case "designation":
					case "applicantCountryCode":
						obj.label = obj.fvalue + " - " + this.ms.translate(`designation.${obj.fvalue}`)
						break;
					case "office":
						obj.label = this.ms.translate(`designation.${obj.fvalue}`) + " - " + this.ms.translate(`office.${obj.fvalue}`)
						break;
					case "niceClass":
						obj.label = obj.fvalue + " - " + this.ms.translate(`niceClass.${obj.fvalue}`)
						break;
				}

				return obj
			})
			.sort((a: any, b: any) => {

				// console.log(`${l}a[columnSortBy]='${a[columnSortBy]}', b[columnSortBy]='${b[columnSortBy]}'`)

				if (a[columnSortBy] !== b[columnSortBy]) {
					if (b[columnSortBy].localeCompare) {

						// Sorting with natural order ("8","9","10" instead of "10","8","9")
						return -1 * columnSortOrder * (b[columnSortBy].localeCompare(a[columnSortBy], undefined, { numeric: true, sensitivity: 'base' }))
					}
					return columnSortOrder * (b[columnSortBy] - a[columnSortBy])
				}
				return b.nbTotalOccurences - a.nbTotalOccurences
			})

		// console.log(`${l}toReturn = `, toReturn)

		return toReturn;
	}

}
